import React, { useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const Login = ({ setUserData }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) setErrors({...errors, email: null});
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) setErrors({...errors, password: null});
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Email validation
    if (!email.trim()) {
      newErrors.email = 'Email is required';
    }
    
    // Password validation
    if (!password) {
      newErrors.password = 'Password is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getUserDataFromToken = () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) return null;
      
      // Decode the token to get the payload
      const decoded = jwtDecode(token);
      console.log("decoded:", decoded);
      return {
        name: decoded.name,
        email: decoded.email,
        country: decoded.country,
        phone_number: decoded.phone_number,
        credentials: decoded.credentials,
      };
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  };

  const handleConfirmClick = async (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      // Here you would typically handle authentication
      console.log('Login attempt with:', email, password);
      const endpoint = "/api/token_obtain_pair/";
      const loginData = {
        email: email,
        password: password
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      });
      // Process the response
      if (response.ok) {
        const data = await response.json();
        // Store tokens
        localStorage.setItem('authToken', data.access); // only reliable info are user name, email and password (not credentials that can change during the session)
        localStorage.setItem('refreshToken', data.refresh);
        setUserData(getUserDataFromToken());
        sessionStorage.setItem("transcript_init", " Welcome to NavID. Here is the transcript of the current uploaded video. Just put into brackets the parts you want to cut. You can use one of our automatic cutters to save time. It will come up with, hopefully, clever suggestions. All those regions will be highlighted in red.");
        sessionStorage.setItem("words", '[" Welcome"," to"," NavID."," Here"," is"," the"," transcript"," of"," the"," current"," uploaded"," video."," Just"," put"," into"," brackets"," the"," parts"," you"," want"," to"," cut."," You"," can"," use"," one"," of"," our"," automatic"," cutters"," to"," save"," time."," It"," will"," come"," up"," with,"," hopefully,"," clever"," suggestions."," All"," those"," regions"," will"," be"," highlighted"," in"," red."]');
        sessionStorage.setItem("words_starts_time", '[0,0.4,0.64,1.58,1.7,1.88,2,2.44,2.74,2.86,3.1,3.56,4.56,4.82,5.02,5.18,5.56,5.82,6.08,6.24,6.4,6.48,7.26,7.4,7.56,7.7,7.9,8,8.12,8.52,9.04,9.22,9.44,10.3,10.4,10.54,10.74,10.94,11.22,12.32,12.32,13.68,13.9,14.16,14.56,14.84,14.98,15.36,15.62]');
        sessionStorage.setItem("words_ends_time", '[0.4,0.64,1.24,1.7,1.88,2,2.44,2.74,2.86,3.1,3.56,4,4.82,5.02,5.18,5.56,5.82,6.08,6.24,6.4,6.48,6.72,7.4,7.56,7.7,7.9,8,8.12,8.52,9.04,9.22,9.44,9.72,10.4,10.54,10.74,10.94,11.14,11.64,12.32,12.92,13.9,14.16,14.56,14.84,14.98,15.36,15.62,15.86]');
        sessionStorage.setItem("words_starts_transcript", '[0,8,11,18,23,26,30,41,44,48,56,65,72,77,81,86,95,99,105,109,114,117,122,126,130,134,138,141,145,155,163,166,171,177,180,185,190,193,199,210,217,230,234,240,248,253,256,268,271]');
        sessionStorage.setItem("media_extension", ".mp4");
        navigate('/workspace');
      } else {
        // Handle error
        //window.alert("Sorry, could not login with this account. Please try again.");
        const errorData = await response.json();
        if (errorData.desc === "unique constraint violation") {
          window.alert("This email alreay has an account. Please try with another email");
        }
        else if (errorData.desc === "integrity error") {
          window.alert("User account could not be created because of integrity errors\n Please try with other information");
        }
        else {
          window.alert("User account could not be created. Please try again");
        }
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-black-300">
      <div className="w-full max-w-md p-8 space-y-8 bg-gray-100 rounded-lg shadow-md">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={(e) => e.preventDefault()}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Email address <span className="text-red-500">*</span>
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm`}
                placeholder="Email address"
                value={email}
                onChange={handleEmailChange}
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

          {/* Password */}
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password <span className="text-red-500">*</span>
            </label>
            <div className="relative mt-1">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                required
                className={`block w-full px-3 py-2 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                placeholder="Create a secure password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
                tabIndex="-1"
              >
                {showPassword ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                  </svg>
                )}
              </button>
            </div>
            {errors.password && (
              <p className="mt-1 text-sm text-red-500">{errors.password}</p>
            )}
          </div>
            
          </div>

          <div>
            <button
              type="submit"
              className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md group hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={handleConfirmClick}
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
import React, { useEffect, useRef } from 'react';

const Cut = ({ stateTranscription, 
                          applyCutRef,
                          clipsTranscriptManual, 
                          setClipsTranscriptManual,
                          clipsTranscriptAutomated, 
                          setClipsTranscriptAutomated,
                          AllClipsSilence, 
                          setAllClipsSilence,
                          minSilenceDuration,
                          setMinSilenceDuration,
                          isSilenceChecked,
                          setIsSilenceChecked,
                          clips,
                          setClips,
                          totalDuration
                        }) => {
  const [transcript, setTranscript] = [...stateTranscription];
  const editorRef = useRef(null);
  const cursorPositionRef = useRef(0);

  /* ----- Functions for silence cuts ------  */

  // cut silence handling
  const handleMinSilenceDuration = (event) => {
    const newMinSilenceDuration = event.target.value;
    setMinSilenceDuration(newMinSilenceDuration);
    if (isSilenceChecked === true) { // update silence cut if remove silence button checked
      filterSilenceClips(newMinSilenceDuration).then(updatedSilenceClipsCut => {
        // 1) remove previous silence clips from all clips
        const clipsNoAudio = clips.filter(d => d["modality"] !== "audio");
        // Add updated silence clips and sort chronologically
        const clipsSorted = [...clipsNoAudio, ...updatedSilenceClipsCut].sort((x, y) => x["start"] - y["start"]);
        setClips(clipsSorted);
      });
    }
  };

  function filterSilenceClipsHelper(AllClipsSilence, 
                                    minSilenceDuration) {
    if (AllClipsSilence.length === 0) {
      return [];
    }
    let silenceClipsCut = []
    for(let i = 0 ; i < AllClipsSilence.length ; i++) {
      const clip = AllClipsSilence[i];
      // add clip only if silence longer than minimum required
      if (clip.duration >= minSilenceDuration) { 
      silenceClipsCut.push(clip);
      }
    }
    return silenceClipsCut;
  }

  async function filterSilenceClips(minSilenceDuration) {
    let AllClipsSilenceDefined = [];
    if (AllClipsSilence === null) { // if all silence clips not yet provided, compute them
      const endpoint = "/api/get_clips_silence/";
      try {
        const token = localStorage.getItem('authToken');
        const formData = new FormData();
        formData.append('words_starts_time', sessionStorage.getItem("words_starts_time"));
        formData.append('words_ends_time', sessionStorage.getItem("words_ends_time"));
        formData.append('total_duration', totalDuration);
        const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`
          // No Content-Type header for FormData
        },
        body: formData
        });
        const data = await response.json();
        AllClipsSilenceDefined = data.clips_silence;
        setAllClipsSilence(AllClipsSilenceDefined);
      }
      catch {
        window.alert("Oups, failed to clip silences");
        AllClipsSilenceDefined = [];
        setAllClipsSilence(AllClipsSilenceDefined);
      }
    }
    else { // AllClipsSilence already defined
      AllClipsSilenceDefined = [...AllClipsSilence];
    }
    // AllClipsSilence now correctly defined
    //console.log("chosen min silence duration:", minSilenceDuration);
    //console.log("AllClipsSilenceDefined:", AllClipsSilenceDefined);
    const silenceClipsCut = filterSilenceClipsHelper(AllClipsSilenceDefined, 
                                              minSilenceDuration
                                              );
    //console.log("obtained silence clips cut:", silenceClipsCut);
    return silenceClipsCut;
  };

  // should be triggered whenever minSilenceDuration changes
  const handleApplySilenceCut = (event) => {
    setIsSilenceChecked(event.target.checked);
    if (event.target.checked === true) { // apply silence cut
      filterSilenceClips(minSilenceDuration).then(updatedSilenceClipsCut => {
        // 1) remove previous silence clips from all clips
        const clipsNoAudio = clips.filter(d => d["modality"] !== "audio");
        // Add updated silence clips and sort chronologically
        //console.log("updatedSilenceClipsCut", updatedSilenceClipsCut);
        const clipsSorted = [...clipsNoAudio, ...updatedSilenceClipsCut].sort((x, y) => x["start"] - y["start"]);
        setClips(clipsSorted);
      });
    }
    else {
        // remove previous silence clips from all clips
        const clipsNoAudio = clips.filter(d => d["modality"] !== "audio");
        setClips(clipsNoAudio);
    }
  };


  
  /* ----- Functions for transcript ------  */

  // Function to get cursor position
  const getCursorPosition = editableDiv => {
    let charCount = 0;
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return 0;

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(editableDiv);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    charCount = preCaretRange.toString().length;

    return charCount;
  };

  // Function to restore the cursor position
  const restoreCursorPosition = (editableDiv, cursorPosition) => {
    const selection = window.getSelection();
    const range = document.createRange();
    let nodeStack = [editableDiv];
    let node;
    let foundStart = false;
    let charCount = 0;

    while ((node = nodeStack.pop())) {
      if (node.nodeType === Node.TEXT_NODE) {
        const nextCharCount = charCount + node.length;
        if (!foundStart && nextCharCount >= cursorPosition) {
          range.setStart(node, cursorPosition - charCount);
          foundStart = true;
        }
        if (foundStart && nextCharCount >= cursorPosition) {
          range.setEnd(node, cursorPosition - charCount);
          break;
        }
        charCount = nextCharCount;
      } else {
        for (let i = node.childNodes.length - 1; i >= 0; i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }
    selection.removeAllRanges();
    selection.addRange(range);
  };


  // Restore cursor position after HTML content updates
  useEffect(() => {
    if (editorRef.current && cursorPositionRef.current !== null) {
      setTimeout(() => {
        restoreCursorPosition(editorRef.current, cursorPositionRef.current);
      }, 0);
    }
  }, [transcript]);

  // add color between brackets and update transcript clips global variables
  async function processText(transcript,
    clipsTranscriptManual,
    clipsTranscriptAutomated
    ) {
    console.log("start processText");
    const endpoint = "/api/color_textarea_update_clips/";
    try {
      const token = localStorage.getItem('authToken');
      console.log("Auth token:", token);
      const formData = new FormData();
      formData.append('transcript_annotated', transcript);
      formData.append('clips_transcript_manual', JSON.stringify(clipsTranscriptManual));
      formData.append('clips_transcript_automatic', JSON.stringify(clipsTranscriptAutomated));
      const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`
        // No Content-Type header for FormData
      },
      body: formData
      });
      const data = await response.json();
      //console.log("data.transcript_annotated_colored", data.transcript_annotated_colored);
      console.log("end processText");
      return data;
    }
    catch {
      console.log("Oups, failed to fetch color_textarea_update_clips");
      window.alert("Oups, failed to fetch color_textarea_update_clips");
      return {
      "transcript_annotated_colored": transcript.replaceAll("[", "").replaceAll("]", ""),
      "clips_automatic_kept": [],
      "clips_manual_updated": []
      }
    }
  };

  // Handle content changes
  const handleInput = e => {
    if (editorRef.current) {
      cursorPositionRef.current = getCursorPosition(editorRef.current);
    }
    const plainText = e.target.innerText;
    console.log("isManualTranscriptModif = True")
    processText(plainText,
                clipsTranscriptManual,
                clipsTranscriptAutomated
                ).then(data => {
                  setTranscript(data.transcript_annotated_colored);
                  setClipsTranscriptAutomated(data.clips_automatic_kept);
                  setClipsTranscriptManual(data.clips_manual_updated);
                  console.log("clips_automatic_kept", data.clips_automatic_kept);
                  console.log("clips_manual_updated", data.clips_manual_updated);
                });
    // change apply cuts button to red
    applyCutRef.current.classList.remove("bg-blue-500");
    applyCutRef.current.classList.remove("hover:bg-blue-600");
    applyCutRef.current.classList.add("bg-red-500");
    applyCutRef.current.classList.add("hover:bg-red-600");
  };


  const handleKeyDown = event => {
    // handle add brackets
    if (event.key === 'a') {
      event.preventDefault();
      const selection = window.getSelection();
      // Only proceed if there is a selection and it's within the editor
      if (selection && !selection.isCollapsed && 
          editorRef.current.contains(selection.anchorNode)) {
      
        const selectedText = selection.toString();
        const bracketedText = `[${selectedText}]`;
        document.execCommand('insertText', false, bracketedText);
        editorRef.current.focus();
      }
    }
    // handle delete brackets
    if (event.key === 'd') {
      event.preventDefault();
      const selection = window.getSelection();
      // Only proceed if there is a selection and it's within the editor
      if (selection && !selection.isCollapsed && 
          editorRef.current.contains(selection.anchorNode)) {
      
        const selectedText = selection.toString();
        const textWithoutBrackets = selectedText.replace(/[\[\]]/g, '');
        document.execCommand('insertText', false, textWithoutBrackets);
        editorRef.current.focus();
      }
    }
  };

  return (
    <div className='w-full mx-auto p-4'>
      <div className='mt-2 mb-6 flex'>
        <span className='text-white'>Remove silences longer than </span>
        <div className="relative mx-2 inline-block">
          <select
            value={minSilenceDuration}
            onChange={(e) => handleMinSilenceDuration(e)}
            className="rounded border px-2 py-1 pr-8 bg-white"
            id="min_duration_silence"
            style={{ maxHeight: '30px' }}
          >
          {Array.from({ length: 21 }, (_, i) => i * 0.5).map(value => (
            <option key={value} value={value}>
              {value.toFixed(1)}
            </option>
          ))}
          </select>
        </div>
        <span className='text-white'> secs </span>

        <label className="ml-auto inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only" // Hide the actual checkbox
            checked={isSilenceChecked}
            onChange={handleApplySilenceCut}
          />
          <div className={`relative w-11 h-6 rounded-full transition-colors duration-200 ease-in-out ${isSilenceChecked ? 'bg-blue-600' : 'bg-gray-400'}`}>
            <div className={`absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-transform duration-200 ease-in-out ${isSilenceChecked ? 'transform translate-x-5' : ''}`}></div>
          </div>
          <span className="ml-3 text-sm font-medium text-white">
            {isSilenceChecked ? 'On' : 'Off'}
          </span>
      </label>
      </div>

      <div
        ref={editorRef}
        className='textarea mx-auto w-full
                  h-56 border bg-black-200 
                  text-base
                  text-gray-400 rounded-lg overflow-y-auto
                  p-2 text-justify outline-none mt-2'
        contentEditable='true'
        spellCheck="false"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        dangerouslySetInnerHTML={{ __html: transcript}}
      />
    </div>
  );
};

export default Cut;

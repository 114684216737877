import React, { useState, useRef } from 'react';
import { Ban } from 'lucide-react';
import LoadingSpinner from "./LoadingSpinner";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
const ffmpeg = new FFmpeg();
await ffmpeg.load();


async function TrimAudio(videoFile, start, duration, filename) {
    // Write video file to FFmpeg's virtual filesystem
    ffmpeg.writeFile('input.mp4', await fetchFile(videoFile));

    // Convert video to audio
    await ffmpeg.exec(['-ss', String(start), // start in seconds
                      //'-fs', maxChunkSize, // Limit file size in bytes
                      '-t', String(duration), // in seconds
                      '-i', 'input.mp4',
                      '-vn', 
                      '-acodec', 'pcm_s16le',
                      'output.wav'
                      ]);
    // Read the resulting audio file
    const data = await ffmpeg.readFile('output.wav');
    //const audioBlob = new Blob([data.buffer], {type: 'audio/wav'});
    const audioBlob = new File([data.buffer], filename, {type: 'audio/wav'});
    // Clean up
    await ffmpeg.deleteFile('input.mp4');
    await ffmpeg.deleteFile('output.wav');
    return audioBlob;
}


// Upload Page Component
const UploadMedia = ({ setSelectedFile, setClips,
                       setVideoUrl, setTranscription,
                       applyCutRef, setPreviousFile,
                       setPreviousVideoUrl, previousFile,
                       previousVideoUrl,
                       setClipsTranscriptManual,
                       setClipsTranscriptAutomated,
                       setAllClipsSilence
                      }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadController, setDownloadController] = useState(null);
  const [transcriptProgressPercent, setTranscriptProgressPercent] = useState(0.);
  const fileInputRef = useRef(null);
  const DURATION_CHUNK = 150; // duration of all chunks (in seconds), except the last chunk
  const [progressMessage, setProgressMessage] = useState("detecting language...");

  const cancelUpload = () => {
    if (downloadController) {
      downloadController.abort();
      // stop backend activity
      const token = localStorage.getItem('authToken');
      console.log("calling /api/cancel_transcript/");
      fetch("/api/cancel_transcript/", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user: "whatever"})
      });
      // go back to previous video state
      setIsLoading(false);
      setSelectedFile(previousFile);
      setVideoUrl(previousVideoUrl);
      // sessionStorage not updated yet
      setTranscription(sessionStorage.getItem("transcript_init"));
      // Reset the file input to allow re-selecting the same file after abortion
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setDownloadController(null);
    }
  }

  async function chunkFile(file, // video File accepted
                duration_chunk, // should generate chunks < 32 Mb (G Cloud default proxy limit)
  ) {
    try {
      let chunks = [];
      let duration_processed = 0;
      console.log("full audio size:", (file.size / (1024 * 1024)).toFixed(2));
      let last_chunk_size = 1000; // in bytes (random init > header_size)
      const header_size = 100; // in bytes
      let counter = 0;
      while (last_chunk_size > header_size) { // continue as long as ffmpeg does not produce an empty file
        // username will later be inserted at the beginning of the filename in the backend
        const chunk_name = `${counter+1}.wav`;
        const chunk = await TrimAudio(file,
                                      duration_processed,
                                      duration_chunk,
                                      chunk_name
                                      );
        if (chunk.size > header_size) {
          chunks.push(chunk);
          console.log("start chunk:", duration_processed);
          //console.log("chunk duration:", duration_chunk);
          console.log("chunk end:", duration_processed + duration_chunk);
          duration_processed += duration_chunk;
          console.log('duration_processed:', duration_processed);
          // updates
          last_chunk_size = chunk.size;
          counter += 1;
        }
        else {
          console.log("stop chunks extraction");
          break;
        }
      }
      return chunks;
    }
    catch (e) {
      window.alert("Sorry, your media format or encoding could not be processed");
      return [];
    }
  }
  const handleFileSelect = async (event) => {
    event.preventDefault()
    const file = event.target.files[0];
    if (file) {
      // variables to build and assign to sessionStorage items
      let transcript_init = "";
      let words = [];
      let words_starts_time = [];
      let words_ends_time = [];
      let words_starts_transcript = [];
      setSelectedFile(file);
      const url = URL.createObjectURL(file);
      setVideoUrl(url);      
      // convert video to audio for size optimization (transcription only needs audio)
      const chunks = await chunkFile(file, DURATION_CHUNK);
      if (chunks.length === 0) {
        return;
      }
      console.log("Nb of audio chunks:", chunks.length);
      setIsLoading(true);
      // reset previous cuts
      setClips([]);
      setClipsTranscriptAutomated([]);
      setClipsTranscriptManual([]);
      setAllClipsSilence(null); // default value set to null, not [] (cf Audio component)

      // reset transcription progress
      setTranscriptProgressPercent(0);
      setProgressMessage("detecting language...");
      // same fetch for every chunks of video, so that it can be cancelled for good
      const controller = new AbortController();
      const signal = controller.signal;
      setDownloadController(controller);
      const token = localStorage.getItem('authToken');
      let count_words = 0;
      let last_progress_update_count = 0;

      // 1) send ALL media chunks to backend
      for (let i = 0 ; i < chunks.length ; i++) {
        console.log("chunk size:", (chunks[i].size / (1024 * 1024)).toFixed(2));
        const formData = new FormData();
        formData.append('chunk', chunks[i]);
        const endpoint = '/api/save_audio_chunk/';
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${token}`
            // No Content-Type header for FormData
          },
          body: formData,
          signal: signal
        });
        /* Handle issues */
        // Check response status first
        if (response.status === 413) {
          window.alert("Request Entity Too Large");
          cancelUpload();
          return;
        }
        if (response.status !== 200) {
          window.alert("Sorry, an unexpected error occured. It is probably related to Google Cloud proxy");
          cancelUpload();
          return;
        }
        const data = await response.json();
        if (data.status === "issue") {
          window.alert("Sorry, your media could not be processed. Please try to upload another media.")
          cancelUpload();
          return;
        }
      }
      
      // apply transcription on FULL media
      const endpoint = "/api/get_transcript/";
      const formData = new FormData();
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`
          // No Content-Type header for FormData
        },
        body: formData,
        signal: signal
      });

      // decode transcript as stream data
      try {
        const decoder = new TextDecoder();
        const reader = response.body.getReader();
        while (true) {
          const {value, done} = await reader.read();
          if (done) break;
          const jsonString = decoder.decode(value); // contains all words data from a Whisper segment
          const jsonStrings = jsonString.trim().split('\n'); // split by word data
          // Parse each JSON object
          jsonStrings.forEach(jsonStr => {
            if (jsonStr) {
              try {
                const jsonObj = JSON.parse(jsonStr);
                // count chars BEFORE new word appended
                const n_total_count = words.reduce((sum, str) => sum + str.length, 0);
                words.push(jsonObj.word);
                words_starts_time.push(jsonObj.word_start_time);
                words_ends_time.push(jsonObj.word_end_time);
                words_starts_transcript.push(n_total_count);
                // update rendered transcript
                transcript_init = transcript_init + jsonObj.word;
                setTranscription(transcript_init);
                count_words += 1;
                // update progress if significant update
                if (count_words >= last_progress_update_count + 20) {
                  setTranscriptProgressPercent(jsonObj.progress);
                  setProgressMessage(`transcribing (${jsonObj.progress} %)`);
                  last_progress_update_count = count_words;
                }
              } 
              catch (error) {
                console.error("Error parsing JSON:", error);
                cancelUpload();
                window.alert("Sorry, the transcript generation went wrong. Please try again later.");
                return;
              }
            }
          }); // end of word level parsing
        }
        // update sessionStorage items
        sessionStorage.setItem("transcript_init", transcript_init);
        sessionStorage.setItem("words", JSON.stringify(words));
        sessionStorage.setItem("words_starts_time", JSON.stringify(words_starts_time));
        sessionStorage.setItem("words_ends_time", JSON.stringify(words_ends_time));
        sessionStorage.setItem("words_starts_transcript", JSON.stringify(words_starts_transcript));
        sessionStorage.setItem("media_extension" , '.' + file.name.split(".").pop());
        // switch to new state only once everything is ok, so that during the whole process, Previous state was maintained
        setPreviousFile(file);
        setPreviousVideoUrl(url);
        // update after iteration over all chunks
        setIsLoading(false);
        // new transcript does not count as modification -> set blue button color
        if (applyCutRef.current) {
          applyCutRef.current.classList.remove("bg-red-500");
          applyCutRef.current.classList.remove("hover:bg-red-600");
          applyCutRef.current.classList.add("bg-blue-500");
          applyCutRef.current.classList.add("hover:bg-blue-600");
        }
        setDownloadController(null);
      }
      catch (error) { 
        console.error('Fetch error:', error);
        window.alert("Sorry, your media could not be processed. Please try to upload another media.");
        cancelUpload();
        return;
      }
    }
  };

  return (
    <div>
      <div className='flex h-20 p-4'>
        <input
          type='file'
          ref={fileInputRef}
          accept='video/*,audio/*'
          onChange={handleFileSelect}
          className='block text-sm text-gray-500
            file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-blue-50 file:text-blue-700
            hover:file:bg-blue-100'
        />
        {isLoading && 
        <button className='text-white text-sm bg-red rounded-lg mr-4'
        onClick={cancelUpload}
        /*onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}*/
        >
          <Ban color='red' size={16}/>
        </button>
        }
        {isLoading && <LoadingSpinner description={progressMessage} />}
      </div>
    </div>
  );
};

export default UploadMedia;

import React from 'react';

const Audio = () => {
  return (
    <div className='w-full mx-auto p-4 flex flex-row space-x-4 mb-96 display: block'>
      <p className='text-white'></p>
    </div>
  );
};

export default Audio;


function LoadingSpinner({description}) {
    return (
      <div className="relative flex items-center">
        <p className="spinner-text">{description}</p>
        <div className="spinner" />
      </div>
    );
}

export default LoadingSpinner;

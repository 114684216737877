import React, { useState } from 'react';

const TemplateDemoToggleable = ({DemoTitle, demoDescription, videoKey, DemoVideoUrl}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const WIDTH_PERCENT = '70%';

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="expandable-container mb-4 ml-24" style={{ position: 'relative'}}>
      {/* Title container with absolute positioning */}
      <div 
        className="title-container text-white rounded border-white border-2"
        onClick={toggleExpand}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          zIndex: 1, // Ensure title stays on top
          width: WIDTH_PERCENT
        }}
      >
        <h2 style={{ marginLeft: 20, padding: '16px 0' }}>
          {DemoTitle}
        </h2>
        <span style={{ 
          marginLeft: 'auto',
          marginRight: '12px'
        }}>
          {isExpanded ? '▼' : '▶'}
        </span>
      </div>

      {/* Content container with proper spacing */}
      <div 
        style={{
          overflow: 'hidden',
          transition: 'all 0.5s ease-in-out',
          height: isExpanded ? 'auto' : '0',
          opacity: isExpanded ? 1 : 0,
          marginTop: '0px' // Space for the title
        }}
      >
        <div className="description-content text-gray-400">
          <p 
          style={{ width: WIDTH_PERCENT }}
          className='text-justify text-sm mt-2'
          dangerouslySetInnerHTML={{ __html: demoDescription}}
          />
          <video
            className='video-demo flex p-4 mt-4'
            key={videoKey}
            autoPlay
            muted
            loop
            playsInline
            controls
            style={{ width: WIDTH_PERCENT}}
            >
            <source src={DemoVideoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default TemplateDemoToggleable;
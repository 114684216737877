import React, { useState, useRef, useEffect } from 'react';
import { ZoomIn, ZoomOut, House, X, Download, Eye, Trash2 } from 'lucide-react';
import LoadingSpinner from "./LoadingSpinner";

const CustomControlBar = ({
  selectedFile,
  stateClips,
  totalDuration,
  progress, // in percent between 0 and 100
  initTotalLength,
  videoRef,
  pixelsPerSecond,
  setPixelsPerSecond,
  setProgress,
  setCurrentTime,
}) => {
  // State initialization
  const [clips, setClips] = [...stateClips];
  const [zoom, setZoom] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [activeClip, setActiveClip] = useState(null);
  const [dragType, setDragType] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visibleTimeMarkers, setVisibleTimeMarkers] = useState([]);
  const [totalLength, setTotalLength] = useState(initTotalLength);
  const [PreviewDescription, setPreviewDescription] = useState('');
  const [previewMode, setPreviewMode] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  // Constants
  const scrollContainerRef = useRef(null);
  const previewRef = useRef(null);
  const divTimeMarkers = useRef(null);
  const timelineRef = useRef(null);
  const animationRef = useRef(null);
  const lastTimeRef = useRef(null);
  const MIN_CLIP_DURATION = 0.1; // maximum precision of the cutter (in secs) !

  const NORMAL_MODE_COLOR = "bg-blue-500";
  const NORMAL_MODE_HOVER = "bg-blue-600";
  const PREVIEW_MODE_COLOR = "bg-blue-800";
  // variables to prevent onClick to trigger when onDoubleClick
  const DOUBLE_CLICK_DELAY = 300; // in milliseconds
  let clickTimer = null;
  // variables to prevent onClick to trigger after clips dragging
  const DRAG_DELAY = 300; // in milliseconds
  const [lastDragEndTime, setLastDragEndTime]  = useState(0); // in milliseconds

  const STEP_ZOOM = 0.5;
  const MIN_ZOOM = 1;
  const MAX_ZOOM = 10;
  let STEP_TIME_MARKER = Math.round(totalDuration / 10);
  const MANUAL_CUT_COLOR = 'bg-purple-500';
  const newClipNbPixels = 30;

  useEffect(() => {
    const scrollToCursor = () => {
      const container = scrollContainerRef.current;
      if (!container) return;
    
      // Calculate cursor position
      const cursorPosition = (progress / 100) * totalDuration * pixelsPerSecond * zoom;
      
      // Get container's scroll information
      const containerWidth = container.clientWidth;
      const scrollLeft = container.scrollLeft;
      const scrollRight = scrollLeft + containerWidth;

      // Define margins (buffer zone near edges)
      const margin = containerWidth * 0.1; // 10% of container width
      
      // Check if cursor is outside visible area (accounting for margins)
      if (cursorPosition < scrollLeft + margin) {
        // Cursor is too far left, scroll left
        container.scrollTo({
          left: Math.max(0, cursorPosition - margin),
          behavior: 'smooth'
        });
      } else if (cursorPosition > scrollRight - margin) {
        // Cursor is too far right, scroll right
        container.scrollTo({
          left: cursorPosition - containerWidth + margin,
          behavior: 'smooth'
        });
      }
    };
    scrollToCursor();
  }, [progress, totalDuration, pixelsPerSecond, zoom]);


  const updateTimeMarkers = scrollLeft => {
    const containerWidth = timelineRef.current.parentElement.clientWidth;
    const visibleTimeStart = scrollLeft / (pixelsPerSecond * zoom);
    const visibleTimeRange = containerWidth / (pixelsPerSecond * zoom);
    const markerInterval = Math.max(1, Math.ceil(STEP_TIME_MARKER / zoom));

    const startTime =
      Math.floor(visibleTimeStart / markerInterval) * markerInterval;
    const endTime =
      Math.ceil((visibleTimeStart + visibleTimeRange) / markerInterval) *
      markerInterval;

    const markers = [];
    for (let time = startTime; time <= endTime; time += markerInterval) {
      if (time >= 0 && time <= totalDuration) {
        markers.push({
          time,
          position: time * pixelsPerSecond * zoom - scrollLeft, // px
        });
      }
    }
    setVisibleTimeMarkers(markers);
  };


  function formatTimeStr(t) {
    const s = (t < 10) ? `0${t}` : t;
    return s;
  }

  function ConvertSecsToHMS(n_total_secs) {
    let hms = "";
    const n_hours = Math.floor(n_total_secs / 3600);
    const n_mins = Math.floor(n_total_secs / 60);
    const n_secs = Math.floor(n_total_secs % 60);
    if (n_hours > 0) {
      hms = hms.concat(`${n_hours}:`);
    }
    hms = (hms === '') ? hms.concat(`${n_mins}:`) : hms.concat(`${formatTimeStr(n_mins)}:`);
    hms = (hms === '') ? hms.concat(`${n_secs}s`) : hms.concat(`${formatTimeStr(n_secs)}`);
    return hms;
  }

  const DownloadCutVideo = async (event) => {
    const endpoint = "/api/get_cut_video/";
    try {
      setIsDownloading(true);
      const token = localStorage.getItem('authToken');
      const formData = new FormData();
      formData.append('video', selectedFile);
      formData.append('video_duration', totalDuration);
      formData.append('clips', JSON.stringify(clips));
      const response = await fetch(endpoint, 
        {method: "POST", 
        headers: {
          'Authorization': `Bearer ${token}`
          // No Content-Type header for FormData
        },
         body: formData
        });
      const blob = await response.blob();
      if (blob.size === 0) {
        window.alert("The output file is empty");
      }
      else {
        // automatic download of the output file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'output' + sessionStorage.getItem("media_extension");
        
        // Trigger download automatically
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        URL.revokeObjectURL(url);
      }
    }
    catch (error) {
      console.error('Error:', error);
    }
    finally {
      setIsDownloading(false);
    }
  };

  const handleScroll = e => {
    const scrollLeft = e.target.scrollLeft;
    setScrollPosition(scrollLeft);
    updateTimeMarkers(scrollLeft);
  };

  const handleZoom = direction => {
    setZoom(prevZoom => {
      const newZoom = prevZoom + direction * STEP_ZOOM;
      return Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoom));
    });
  };

  const handleResetZoom = () => {
    setZoom(1);
  };

  const getTimeFromPosition = clientX => {
    if (!timelineRef.current) return 0;
    const rect = timelineRef.current.getBoundingClientRect();
    const x = clientX - rect.left;
    return Math.max(0, x / (pixelsPerSecond * zoom));
  };

  const createClip = e => {
    if (activeClip) return;

    // Cancel any pending single-click timer
    if (clickTimer) {
      clearTimeout(clickTimer);
      clickTimer = null;
    }

    const clickTime = getTimeFromPosition(e.clientX);
    const currentMaxId = Math.max(...clips.map(obj => obj.id));
    // handle non unique id, which could happen when brackets to clips called after manual clips
    // by adding a big gap of 1000 between text clips and manual clips ids
    const newId = currentMaxId >= 1000 ? currentMaxId + 1 : 1000;
    console.log("pixelsPerSecond:", zoom * totalLength / totalDuration);
    const newClip = {
      id: newId,
      start: clickTime,
      duration: newClipNbPixels / (zoom * totalLength / totalDuration), // in secs
      color: MANUAL_CUT_COLOR,
      title: "",
      modality: "manual"
    };
    // Sort the clips chronologically
    const clipsSorted = [...clips, newClip].sort((x, y) => x["start"] - y["start"]);
    setClips(clipsSorted);
  };


  const moveCursor = e => {
    if (activeClip) return;

    // Ignore clicks that happen right after drag end
    if (Date.now() - lastDragEndTime < DRAG_DELAY) {
      return;
    }

    // Cancel the click action if it's actually part of a double-click
    if (clickTimer === null) {
      clickTimer = setTimeout(() => {
        // This will only execute if a double-click doesn't happen
        const clickTime = getTimeFromPosition(e.clientX);
        const updatedProgress = 100 * clickTime / totalDuration;
        setProgress(updatedProgress);
        setCurrentTime(clickTime);
        videoRef.current.currentTime = clickTime;
        clickTimer = null;
      }, DOUBLE_CLICK_DELAY);
    }
  };


  const deleteClip = (e, clipId) => {
    e.stopPropagation();
    setClips(prev => prev.filter(clip => clip.id !== clipId));
  };

  const startDrag = (e, clipId, type) => {
    e.stopPropagation();
    setDragStart(e.clientX);
    setActiveClip(clipId);
    setDragType(type);
    setIsPlaying(false);
  };

  const handleDrag = e => {
    if (!activeClip || !dragStart || !timelineRef.current) return;

    const currentPosition = getTimeFromPosition(e.clientX);

    setClips(prevClips =>
      prevClips.map(c => {
        if (c.id === activeClip) {
          if (dragType === 'start') {
            const endTime = c.start + c.duration;
            return {
              ...c,
              start: Math.min(currentPosition, endTime - MIN_CLIP_DURATION),
              duration: Math.max(MIN_CLIP_DURATION, endTime - currentPosition),
            };
          } else if (dragType === 'end') {
            return {
              ...c,
              duration: Math.max(MIN_CLIP_DURATION, currentPosition - c.start),
            };
          }
        }
        return c;
      })
    );
  };

  const stopDrag = () => {
    setDragStart(null);
    setActiveClip(null);
    setDragType(null);
    setLastDragEndTime(Date.now());
  };

  useEffect(() => {
    if (totalLength && totalDuration) {
      // Make sure both values exist
      setPixelsPerSecond(totalLength / totalDuration);
    }
  }, [totalLength, totalDuration]);

  useEffect(() => {
    if (activeClip) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', stopDrag);
      return () => {
        window.removeEventListener('mousemove', handleDrag);
        window.removeEventListener('mouseup', stopDrag);
      };
    }
  }, [activeClip, dragStart]);

  const animate = timestamp => {
    if (!lastTimeRef.current) lastTimeRef.current = timestamp;
    lastTimeRef.current = timestamp;

    if (isPlaying) {
      animationRef.current = requestAnimationFrame(animate);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      lastTimeRef.current = null;
      animationRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(animationRef.current);
    }
    return () => cancelAnimationFrame(animationRef.current);
  }, [isPlaying]);

  useEffect(() => {
      updateTimeMarkers(scrollPosition);
  }, [zoom, totalDuration, pixelsPerSecond]);

  useEffect(() => {
    const updateTotalLength = () => {
      if (divTimeMarkers.current && timelineRef.current) {
        setTotalLength(divTimeMarkers.current.clientWidth);
        // also update number of pixels per second
        setPixelsPerSecond(
          (divTimeMarkers.current.clientWidth * zoom) / totalDuration
        );
        // update the total length of the timeline bar as well
        timelineRef.current.setAttribute(
          'width',
          divTimeMarkers.current.clientWidth * zoom
        );
      }
    };
    window.addEventListener('resize', updateTotalLength);
    return () => window.removeEventListener('resize', updateTotalLength);
  }, []);

  const changePreviewMode = e => {
    if (previewMode) {
      setPreviewMode(false);
    }
    else {
      setPreviewMode(true);
    }
  }

  const deleteAllClips = e => {
    setClips([]);
  }

  useEffect(() => {
    const jumpCuts = () => {
      const margin = 0.01;
      const currentTime = videoRef.current.currentTime;
      let left = 0;
      let right = clips.length - 1;
      while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const clip = clips[mid];
        const clip_end = clip.start + clip.duration;
        
        if (currentTime >= clip.start && currentTime < clip_end) {
            videoRef.current.currentTime = clip_end + margin;
            return;
        }
        if (currentTime < clip.start) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
      }
      return;
    };

    if (previewMode) {
      if (previewRef.current !== null && videoRef.current !== null) {
        previewRef.current.classList.remove(`hover:${NORMAL_MODE_HOVER}`);
        previewRef.current.classList.remove(NORMAL_MODE_COLOR);
        previewRef.current.classList.add(PREVIEW_MODE_COLOR);
        videoRef.current.addEventListener("timeupdate", jumpCuts);
      }
    }
    return () => {
      // change color
      if (previewRef.current !== null && videoRef.current !== null) {
        previewRef.current.classList.remove(PREVIEW_MODE_COLOR);
        previewRef.current.classList.add(NORMAL_MODE_COLOR);
        previewRef.current.classList.add(`hover:${NORMAL_MODE_HOVER}`);
        // no need to track time cursor value to avoid cuts
        videoRef.current.removeEventListener('timeupdate', jumpCuts);
      }
    }
  }, [previewMode, videoRef, clips]);

  return (
    <div className='mx-auto h-50 max-w-xl border border-gray-300 rounded-lg bg-black-200 p-4 mt-5 box-border'>
      <div className='flex justify-between mb-2' ref={divTimeMarkers}>
        <div className='flex gap-1'>
          <button
            onClick={() => deleteAllClips()}
            className='p-2 bg-gray-300 rounded hover:bg-gray-200 mr-4'
          >
            <Trash2 size={16} />
          </button>

          <button
            onClick={() => handleZoom(-1)}
            className='p-2 bg-gray-300 rounded hover:bg-gray-200'
          >
            <ZoomOut size={16} />
          </button>
          <button
            onClick={handleResetZoom}
            className='p-2 bg-gray-300 rounded hover:bg-gray-200'
          >
            <House size={16} />
          </button>
          <button
            onClick={() => handleZoom(1)}
            className='p-2 bg-gray-300 rounded hover:bg-gray-200'
          >
            <ZoomIn size={16} />
          </button>
          <span className='text-sm py-2 text-white'>Zoom: {zoom.toFixed(1)}x</span>
        </div>
        <div className='flex gap-1'>
          {isDownloading && <LoadingSpinner description={"downloading ..."} />}
          <div className='group relative'>
            <span className='text-gray-300 text-xs italic mr-2'>{PreviewDescription}</span>
            <button 
            ref={previewRef}
            className='bg-blue-500 hover:bg-blue-600 rounded-lg text-white p-2'
            onMouseOver={() => setPreviewDescription('preview mode: skip cuts')}
            onMouseLeave={() => setPreviewDescription('')}
            onClick={changePreviewMode}>
              <Eye />
            </button>
          </div>
          <div className='group relative'>
            <button 
              className='bg-blue-500 hover:bg-blue-600 rounded-lg text-white p-2'
              onClick={DownloadCutVideo}
            >
              <Download />
            </button>
          </div>
        </div>
      </div>

      <div className='relative mb-4 overflow-hidden'>
        <div className='h-6'>
          {visibleTimeMarkers.map(({ time, position }) => (
            <div
              key={time}
              className='absolute text-xs text-white'
              style={{ left: `${position}px` }}
            >
              {ConvertSecsToHMS(time)}
            </div>
          ))}
        </div>
      </div>

      <div 
      ref={scrollContainerRef}
      className='overflow-x-auto' 
      onScroll={handleScroll}
      >
        <div
          className='relative h-16 bg-gray-700 rounded'
          ref={timelineRef}
          onDoubleClick={createClip}
          onClick={moveCursor}
          //style={{ width: `${totalDuration * basePixelsPerSecond * zoom}px` }}
          style={{ width: `${totalLength * zoom}px` }}
        >
          <div
            className='absolute w-0.5 bg-blue-500 h-full z-20'
            style={{
              left: `${
                (progress / 100) * totalDuration * pixelsPerSecond * zoom
              }px`,
            }}
          />

          {clips.map(clip => (
            <div
              key={clip.id}
              className={`absolute h-full ${clip.color} group z-10`}
              style={{
                left: `${clip.start * pixelsPerSecond * zoom}px`,
                width: `${clip.duration * pixelsPerSecond * zoom}px`,
              }}
            >
              <button
                className='absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full text-white opacity-0 group-hover:opacity-100 flex items-center justify-center'
                onClick={e => deleteClip(e, clip.id)}
              >
                <X size={12} />
              </button>

              <div
                className='absolute left-0 w-1 h-full cursor-ew-resize cursor-pointer bg-white opacity-0 group-hover:opacity-50'
                onMouseDown={e => startDrag(e, clip.id, 'start')}
              />

              <div
                className='absolute right-0 w-1 h-full cursor-ew-resize cursor-pointer bg-white opacity-0 group-hover:opacity-50'
                onMouseDown={e => startDrag(e, clip.id, 'end')}
              />
            </div>
          ))}
        </div>
      </div>

      <div className='mt-4 flex justify-between items-center'>
        {/*<span className='text-sm text-white'>
          Time: {ConvertSecsToHMS(((progress * totalDuration) / 100).toFixed(1))}
        </span>*/}
        <span className='text-sm text-gray-200'>
          Double-click timeline to add clip
        </span>
      </div>
    </div>
  );
};

export default CustomControlBar;

import React, { useState, useRef } from 'react';
import { BrowserRouter,
         Routes, Route,
          Navigate, Outlet
       } from 'react-router-dom';
import Workspace from './Workspace';
import UserSettings from './UserSettings';
import LandingPage from './LandingPage';
import Demo from './Demo';
import Login from './Login';
import Pricing from './Pricing';
import SignUp from './SignUp';
import VerificationSent from './VerificationSent';
import AddCredentials from './AddCredentials';
import EmailConfirmation from './EmailConfirmation';
import BackgroundVideoComponent from './BackgroundVideoComponent';


// Protected route component
function ProtectedRoute() {
  // Check if user is authenticated
  const isAuthenticated = () => {
    return localStorage.getItem('authToken') !== null;
  };
  
  // If authenticated, render the child routes (Outlet)
  // If not, redirect to login page
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}

function App() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    country: '',
    phone_number: '',
    credentials: '',
  });

  // constants to init the Workspace components
  const initVideoUrl = "/navid_with_logo.mp4"
  // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4';
  const [transcription, setTranscription] = useState('Welcome to NavID. Here is the transcript of the current uploaded video. <span class="text-red-500">[Just put into brackets the parts you want to cut]</span>. You can use one of our <span class="text-red-500">[automatic]</span> cutters to save time. It will come up with, hopefully, clever suggestions. All those regions will be <span class="text-red-500">[highlighted]</span> in red.');
  const initTotalLength = 576 - 16 * 2; // in px, cf "max-w-xl" and "p-4" Tailwind classes usage in CustomControlBar and VideoTimeline
  const [progress, setProgress] = useState(0); // video progress value between 0 and 100 (in percent)
  const [clips, setClips] = useState([]); // clips to be removed in TIME SPACE. contains the clips from all modalities (differentiated by "modality" key)
  const [totalDuration, setTotalDuration] = useState(16);
  const [videoUrl, setVideoUrl] = useState(initVideoUrl);
  const [selectedFile, setSelectedFile] = useState(null);
  // in case of upload cancellation (session Storage not suited for these data types)
  const [previousVideoUrl, setPreviousVideoUrl] = useState(initVideoUrl);
  const [previousFile, setPreviousFile] = useState(null);
  // variables encapsulating brackets positions
  const [clipsTranscriptManual, setClipsTranscriptManual] = useState([]); // in red
  const [clipsTranscriptAutomated, setClipsTranscriptAutomated] = useState([]); // in purple
  // task is whether "cut", "audio" or "zoom"
  const [task, setTask] = useState("cut");
  // variable storing the exhaustive list of silence segments to avoid re-fetching every time user changes min_silence_duration param
  const [AllClipsSilence, setAllClipsSilence] = useState(null);

  // states for Audio component
  const [minSilenceDuration, setMinSilenceDuration] = useState(0.2);
  const [isSilenceChecked, setIsSilenceChecked] = useState(false);

  const applyCutRef = useRef(null);
  const videoRef = useRef(null);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/back" element={<BackgroundVideoComponent/>}/>
        <Route path="/info" element={<Demo />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/login" element={
          <Login 
            setUserData={setUserData}
          />
        } />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/account_verification" element={<VerificationSent/>} />
        <Route path="/accounts/confirm-email/:key" element={<EmailConfirmation />} />
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/workspace" element={
            <Workspace
              credentials={userData.credentials}
              initVideoUrl={initVideoUrl}
              transcription={transcription}
              setTranscription={setTranscription}
              initTotalLength={initTotalLength}
              progress={progress}
              setProgress={setProgress}
              clips={clips}
              setClips={setClips}
              totalDuration={totalDuration}
              setTotalDuration={setTotalDuration}
              videoUrl={videoUrl}
              setVideoUrl={setVideoUrl}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              previousVideoUrl={previousVideoUrl}
              setPreviousVideoUrl={setPreviousVideoUrl}
              previousFile={previousFile}
              setPreviousFile={setPreviousFile}
              clipsTranscriptManual={clipsTranscriptManual}
              setClipsTranscriptManual={setClipsTranscriptManual}
              clipsTranscriptAutomated={clipsTranscriptAutomated}
              setClipsTranscriptAutomated={setClipsTranscriptAutomated}
              task={task}
              setTask={setTask}
              AllClipsSilence={AllClipsSilence}
              setAllClipsSilence={setAllClipsSilence}
              minSilenceDuration={minSilenceDuration}
              setMinSilenceDuration={setMinSilenceDuration}
              isSilenceChecked={isSilenceChecked}
              setIsSilenceChecked={setIsSilenceChecked}
              applyCutRef={applyCutRef}
              videoRef={videoRef}
            />
          } />
          <Route path="/settings" element={
            <UserSettings 
              userData={userData}
              setUserData={setUserData}
            />
          } />
          <Route path="/credentials" element={
            <AddCredentials 
              currentCredentials={userData.credentials}
              setUserData={setUserData}
            />
          } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';

const UserSettings = ({ 
    userData,
    setUserData
  }) => {
  const navigate = useNavigate();
  
  const [isEditing, setIsEditing] = useState({
    name: false,
    email: false,
    country: false,
    phone_number: false
  });
  
  const [countries] = useState([
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'AU', name: 'Australia' },
    { code: 'FR', name: 'France' },
    { code: 'DE', name: 'Germany' },
    { code: 'JP', name: 'Japan' },
    { code: 'CN', name: 'China' },
    { code: 'IN', name: 'India' },
    { code: 'BR', name: 'Brazil' }
  ]);
  
  const [formData, setFormData] = useState(userData);
  const [successMessage, setSuccessMessage] = useState('');
  
  // Update formData when userData changes
  useEffect(() => {
    setFormData(userData);
  }, [userData]);
  
  const handleEdit = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };
  
  const handleCancel = (field) => {
    setFormData({ ...formData, [field]: userData[field] });
    setIsEditing({ ...isEditing, [field]: false });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSave = async (field) => {
    // update data frontend side
    setUserData({ ...userData, [field]: formData[field] });
    setIsEditing({ ...isEditing, [field]: false });
    // apdate data in DB
    const token = localStorage.getItem('authToken');
    const endpoint = "/api/update_user_field/";
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"field": field, "value": formData[field]})
    });
    const data_endpoint = await response.json();
    if (data_endpoint.status === "ok") {
      // Show success message
      const field_name_display = (field === "phone_number") ? "Phone Number" : field.charAt(0).toUpperCase() + field.slice(1);
      setSuccessMessage(`${field_name_display} updated successfully!`);
      setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
    }
    else {
      window.alert("Sorry, could not save updated field value in DB");
    }
  };

  
  const handleAddCredentials = () => {
    navigate('/credentials');
  };
  
  const getCountryName = (code) => {
    const country = countries.find(c => c.code === code);
    return country ? country.name : code;
  };
  
  return (
    <div className="min-h-screen bg-black-300">
      <NavBar />
      <div className="max-w-4xl mx-auto p-6 -mt-6">
        <div className="bg-gray-100 shadow-md rounded-lg overflow-hidden">

          <h1 className="text-2xl font-bold mb-6 text-center mt-4">User Settings</h1>
          
          {successMessage && (
            <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-md">
              {successMessage}
            </div>
          )}

          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Personal Information</h2>
            
            {/* Username */}
            <div className="mb-6 pb-4 border-b border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Username
                </label>
                {!isEditing.name ? (
                  <button 
                    onClick={() => handleEdit('name')}
                    className="text-sm text-blue-500 hover:text-blue-700"
                  >
                    Edit
                  </button>
                ) : (
                  <div className="space-x-2">
                    <button 
                      onClick={() => handleSave('name')}
                      className="text-sm text-green-500 hover:text-green-700"
                    >
                      Save
                    </button>
                    <button 
                      onClick={() => handleCancel('name')}
                      className="text-sm text-red-500 hover:text-red-700"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              {isEditing.name ? (
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              ) : (
                <div className="text-gray-900">{userData.name}</div>
              )}
            </div>
            
            {/* Email */}
            <div className="mb-6 pb-4 border-b border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email Address
                </label>
              </div>
              <div className="text-gray-900">{userData.email}</div>
            </div>
            
            {/* Country */}
            <div className="mb-6 pb-4 border-b border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Country
                </label>
                {!isEditing.country ? (
                  <button 
                    onClick={() => handleEdit('country')}
                    className="text-sm text-blue-500 hover:text-blue-700"
                  >
                    Edit
                  </button>
                ) : (
                  <div className="space-x-2">
                    <button 
                      onClick={() => handleSave('country')}
                      className="text-sm text-green-500 hover:text-green-700"
                    >
                      Save
                    </button>
                    <button 
                      onClick={() => handleCancel('country')}
                      className="text-sm text-red-500 hover:text-red-700"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              {isEditing.country ? (
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="text-gray-900">{getCountryName(userData.country)}</div>
              )}
            </div>
            
            {/* Phone Number */}
            <div className="mb-6 pb-4 border-b border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Phone Number
                </label>
                {!isEditing.phone_number ? (
                  <button 
                    onClick={() => handleEdit('phone_number')}
                    className="text-sm text-blue-500 hover:text-blue-700"
                  >
                    Edit
                  </button>
                ) : (
                  <div className="space-x-2">
                    <button 
                      onClick={() => handleSave('phone_number')}
                      className="text-sm text-green-500 hover:text-green-700"
                    >
                      Save
                    </button>
                    <button 
                      onClick={() => handleCancel('phone_number')}
                      className="text-sm text-red-500 hover:text-red-700"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              {isEditing.phone_number ? (
                <input
                  type="tel"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              ) : (
                <div className="text-gray-900">{userData.phone_number}</div>
              )}
            </div>
            
            {/* Credentials */}
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Remaining Processing Minutes
                </label>
                <button 
                  onClick={handleAddCredentials}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Credentials
                </button>
              </div>
              <div className="flex items-center">
                <div className="text-2xl font-bold text-gray-900">{userData.credentials}</div>
                <span className="ml-2 text-sm text-gray-500">minutes remaining</span>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                You can process videos up to {userData.credentials} more minutes in length.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;

import React, { useEffect, useState } from 'react';
import VideoTimeline from './VideoTimeline';
import Cut from './Cut';
import CustomControlBar from './CustomControlBar';
import ModelsSetting from './ModelsSetting';
import ApplyCuts from './ApplyCuts';
import UploadMedia from './UploadMedia';
import Zoom from './Zoom';
import Audio from './Audio';
import NavBar from './NavBar';

const Workspace = ({ 
  credentials,
  initVideoUrl,
  transcription,
  setTranscription,
  initTotalLength,
  progress,
  setProgress,
  clips,
  setClips,
  totalDuration,
  setTotalDuration,
  videoUrl,
  setVideoUrl,
  selectedFile,
  setSelectedFile,
  previousVideoUrl,
  setPreviousVideoUrl,
  previousFile,
  setPreviousFile,
  clipsTranscriptManual,
  setClipsTranscriptManual,
  clipsTranscriptAutomated,
  setClipsTranscriptAutomated,
  task,
  setTask,
  AllClipsSilence,
  setAllClipsSilence,
  minSilenceDuration,
  setMinSilenceDuration,
  isSilenceChecked,
  setIsSilenceChecked,
  applyCutRef,
  videoRef,
  mediaLanguage,
  setMediaLanguage
  }) => {
  const [pixelsPerSecond, setPixelsPerSecond] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [zooms, setZooms] = useState(
    [{
      start: 0,
      end: 10,
      id: 1,
      centerX: 0.25, // 0-1 range (percentage of width)
      centerY: 0.25,  // 0-1 range (percentage of height)
      scale: 2,
    },
    ]);
    const [selectedZoomId, setSelectedZoomId] = useState(1);

  useEffect(() => {
    async function initializeFile() {
      try {
        const response = await fetch(initVideoUrl);
        const blob = await response.blob();
        const file = new File([blob], 'navid_with_logo.mp4', { 
          type: blob.type || 'video/mp4' 
        });
        // Init the selected file states
        setSelectedFile(file);
        setPreviousFile(file);
      } catch (error) {
        console.error('Error initializing file:', error);
      }
    }
    initializeFile();
  }, []);

  const renderTaskBlock = () => {
    switch (task) {
      case 'cut':
        return  (<div className='w-full box-border mx-auto border border-gray-300 rounded-lg'>
                  {/*<ModelsSetting
                    transcription={transcription}
                    setTranscription={setTranscription}
                  />*/}
                  <Cut
                    stateTranscription={[transcription, setTranscription]}
                    applyCutRef={applyCutRef}
                    clipsTranscriptManual={clipsTranscriptManual}
                    setClipsTranscriptManual={setClipsTranscriptManual}
                    clipsTranscriptAutomated={clipsTranscriptAutomated}
                    setClipsTranscriptAutomated={setClipsTranscriptAutomated}
                    AllClipsSilence={AllClipsSilence}
                    setAllClipsSilence={setAllClipsSilence}
                    minSilenceDuration={minSilenceDuration}
                    setMinSilenceDuration={setMinSilenceDuration}
                    isSilenceChecked={isSilenceChecked}
                    setIsSilenceChecked={setIsSilenceChecked}
                    clips={clips}
                    setClips={setClips}
                    totalDuration={totalDuration}

                  />
                  <div>
                    <p className='text-white text-xs ml-4'>Shortcuts</p>
                    <ul>
                      <li className='text-white text-xs ml-4' style={{ whiteSpace: 'pre' }}>• add brackets around highlighted region:     highlight text  →  Press 'a'</li>
                      <li className='text-white text-xs ml-4' style={{ whiteSpace: 'pre' }}>• delete all brackets in highlighted region:     highlight text  → Press 'd'</li>
                    </ul>
                  </div>
                  <ApplyCuts 
                    transcription={transcription}
                    clips={clips}
                    setClips={setClips}
                    applyCutRef={applyCutRef}
                  />
                </div>);

      case 'audio':
        return (<div className='w-full box-border mx-auto border border-gray-300 rounded-lg'>
                  <Audio
                    minSilenceDuration={minSilenceDuration}
                    setMinSilenceDuration={setMinSilenceDuration}
                    isSilenceChecked={isSilenceChecked}
                    setIsSilenceChecked={setIsSilenceChecked}
                    AllClipsSilence={AllClipsSilence}
                    setAllClipsSilence={setAllClipsSilence}
                    clips={clips}
                    setClips={setClips}
                    totalDuration={totalDuration}
                  />
                </div>
                );
      case 'zoom':
        return (<div className='w-full box-border mx-auto border border-gray-300 rounded-lg'>
                  <Zoom
                  totalDuration={totalDuration}
                  zooms={zooms}
                  setZooms={setZooms}
                  selectedZoomId={selectedZoomId}
                  setSelectedZoomId={setSelectedZoomId}
                  />
                </div>
              );
      default: // task = "cut"
        (<div className='w-full box-border mx-auto border border-gray-300 rounded-lg'>
          {/*<ModelsSetting
            transcription={transcription}
            setTranscription={setTranscription}
          />*/}
          <Cut
            stateTranscription={[transcription, setTranscription]}
            applyCutRef={applyCutRef}
            clipsTranscriptManual={clipsTranscriptManual}
            setClipsTranscriptManual={setClipsTranscriptManual}
            clipsTranscriptAutomated={clipsTranscriptAutomated}
            setClipsTranscriptAutomated={setClipsTranscriptAutomated}
            AllClipsSilence={AllClipsSilence}
            setAllClipsSilence={setAllClipsSilence}
            minSilenceDuration={minSilenceDuration}
            setMinSilenceDuration={setMinSilenceDuration}
            isSilenceChecked={isSilenceChecked}
            setIsSilenceChecked={setIsSilenceChecked}
            clips={clips}
            setClips={setClips}
            totalDuration={totalDuration}
          />
          <ApplyCuts 
            transcription={transcription}
            clips={clips}
            setClips={setClips}
            applyCutRef={applyCutRef}
          />
        </div>);
    }
  };

  return (
    <div>
        <NavBar/>
        <p className='text-white p-4 ml-10'>Remaining credentials: {credentials}</p>
      <div className='ml-10 flex'>
        <div className='w-5/12'>
          <UploadMedia
            setSelectedFile={setSelectedFile}
            setClips={setClips}
            setVideoUrl={setVideoUrl}
            setPreviousVideoUrl={setPreviousVideoUrl}
            setPreviousFile={setPreviousFile}
            previousVideoUrl={previousVideoUrl}
            previousFile={previousFile}
            setTranscription={setTranscription}
            applyCutRef={applyCutRef}
            setClipsTranscriptAutomated={setClipsTranscriptAutomated}
            setClipsTranscriptManual={setClipsTranscriptManual}
            setAllClipsSilence={setAllClipsSilence}
            mediaLanguage={mediaLanguage}
            setMediaLanguage={setMediaLanguage}
          />
          <div className='flex w-full justify-center mb-1'>

            <button 
            className={
              `rounded p-2 text-sm m-1
              ${task === "cut" ? "bg-blue-500" : "bg-gray-300 hover:bg-gray-200"}
              `}
            onClick={() => setTask('cut')}
            >Cut</button>

            <button 
            className={
              `rounded p-2 text-sm m-1
              ${task === "audio" ? "bg-blue-500" : "bg-gray-300 hover:bg-gray-200"}
              `} 
            onClick={() => setTask('audio')}
            >Audio</button>

            <button
            className={
              `rounded p-2 text-sm m-1
              ${task === "zoom" ? "bg-blue-500" : "bg-gray-300 hover:bg-gray-200"}
              `}
            onClick={() => setTask('zoom')}
            >Zoom</button>

          </div>
          {renderTaskBlock()}
        </div>
        <div className='w-7/12'>
          <VideoTimeline
            videoRef={videoRef}
            videoUrl={videoUrl}
            progress={progress}
            setProgress={setProgress}
            totalDuration={totalDuration}
            setTotalDuration={setTotalDuration}
            pixelsPerSecond={pixelsPerSecond}
            clips={clips}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            zooms={zooms}
            setSelectedZoomId={setSelectedZoomId}
          />
          <CustomControlBar
            selectedFile={selectedFile}
            stateClips={[clips, setClips]}
            totalDuration={totalDuration}
            progress={progress}
            initTotalLength={initTotalLength}
            videoRef={videoRef}
            pixelsPerSecond={pixelsPerSecond}
            setPixelsPerSecond={setPixelsPerSecond}
            setProgress={setProgress}
            setCurrentTime={setCurrentTime}
          />
        </div>
      </div>
    </div>
  );
};

export default Workspace;

import React, { useState, useEffect} from 'react';
import { Scissors} from 'lucide-react';

const ApplyCuts = ({transcription, clips, setClips, applyCutRef}) => {
    const [applyButtonDescription, setApplyButtonDescription] = useState('');

    useEffect(() => {
        if (applyCutRef.current) {
        const handleClick = async (event) => {
            event.preventDefault();
            const token = localStorage.getItem('authToken');
            const endpoint = '/api/from_transcript_annotated_to_clips/';
            try {

                const formData = new FormData();
                formData.append('transcript_init', sessionStorage.getItem("transcript_init"));
                formData.append('transcript_annotated', transcription);
                formData.append('words_starts_transcript', sessionStorage.getItem("words_starts_transcript"));
                formData.append('words_starts_time', sessionStorage.getItem("words_starts_time"));
                formData.append('words_ends_time', sessionStorage.getItem("words_ends_time"));
                const response = await fetch(endpoint, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`
                        // No Content-Type header for FormData
                      },
                    body: formData,
                });
                const data = await response.json();
                if (data.status === "ok") {
                    const updatedTextClips = data.clips;
                    // 1) remove previous text clips from all clips
                    const clipsNoText = clips.filter(d => d["modality"] !== "text");
                    // Add updated text clips and sort chronologically
                    const clipsSorted = [...clipsNoText, ...updatedTextClips].sort((x, y) => x["start"] - y["start"]);
                    setClips(clipsSorted);
                }
                if (data.status === "modified_transcript") {
                    window.alert("modified transcript");
                }
                if (data.status === "issue") {
                    window.alert("Sorry, there was an issue. Please try again.");
                }
            }
            catch (error) {
                console.error('Fetch error:', error);
            }
            finally {
                // all modifications have been applied -> go back to blue
                applyCutRef.current.classList.remove("bg-red-500");
                applyCutRef.current.classList.remove("hover:bg-red-600");
                applyCutRef.current.classList.add("bg-blue-500");
                applyCutRef.current.classList.add("hover:bg-blue-600");
            }
        };
        applyCutRef.current.addEventListener('click', handleClick);
        // Clean up
        return () => {
            if (applyCutRef.current !== null) {
                applyCutRef.current.removeEventListener('click', handleClick);
            }
        };
        }
    }, [transcription]);

    return (
        <div className='flex w-full p-4 border-none justify-end'>
            <span className='text-gray-300 text-xs italic mt-2 mr-2'>{applyButtonDescription}</span>
            <button 
                ref={applyCutRef}
                className='bg-blue-500 hover:bg-blue-600 rounded-lg text-white px-4 py-2'
                onMouseOver={() => setApplyButtonDescription('apply your cuts')}
                onMouseLeave={() => setApplyButtonDescription('')}
                >
                <Scissors/>
            </button>
        </div>
    )
};

export default ApplyCuts;

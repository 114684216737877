import React from 'react';
import { useNavigate } from 'react-router-dom';

import Carousel from './Carousel';

const LandingPage = () => {

  const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate('/demo');
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handlePricingClick = () => {
    navigate('/pricing');
  };

  const Footer = () => {
    return (
      <footer className="absolute bottom-0 left-0 right-0 z-20 bg-black-100 bg-opacity-70 py-4">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="text-gray-300 text-sm">
            © 2025 NavID. All rights reserved.
          </div>
          <div className="flex space-x-4">
            <a 
              href="/support" 
              className="text-gray-300 hover:text-blue-500 transition duration-300 text-sm"
            >
              Support
            </a>
            <a 
              href="/contact" 
              className="text-gray-300 hover:text-blue-500 transition duration-300 text-sm"
            >
              Contact
            </a>
            <a 
              href="/privacy" 
              className="text-gray-300 hover:text-blue-500 transition duration-300 text-sm"
            >
              Privacy
            </a>
            <a 
              href="/terms" 
              className="text-gray-300 hover:text-blue-500 transition duration-300 text-sm"
            >
              Terms
            </a>
          </div>
        </div>
      </footer>
    );
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black-300 p-4">

      <div className="z-10 min-h-screen flex flex-col items-center justify-center bg-black-300 p-4">
        {/* Top right navigation */}
        <div className="absolute top-6 right-6 z-20 flex space-x-4">
          <button className="border border-2 border-white hover:text-gray-500 text-white font-medium py-2 px-4 rounded-lg transition duration-300 hover:bg-gray-100"
          onClick={handlePricingClick}
          >
            Pricing
          </button>
          <button className="border border-2 border-white hover:text-gray-500 text-white font-medium py-2 px-4 rounded-lg transition duration-300 hover:bg-gray-100"
          onClick={handleLoginClick}
          >
            Login
          </button>
          <button className="border border-2 border-white hover:text-gray-500 text-white font-medium py-2 px-4 rounded-lg transition duration-300"
          onClick={handleSignUpClick}
          >
            Sign Up
          </button>
        </div>

        <div className="text-center max-w-2xl">
          {/* Logo */}
          <div className="mb-8">
          <img src='/logo_navID.svg' width="350" alt="logo" className='mx-auto'/>
          </div>

          {/* Slogan */}
          <h1 className="text-3xl font-bold text-blue-500 mb-2">
            Navigate in the chaos of videos - Cut like a pro
          </h1>
          <h1 className="text-2xl font-bold text-blue-700 mb-2">
          🎙️ Podcasts | Presentations | MOOCs | Vlogs 🎬
          </h1>

          {/* Description */}
          <p className="text-lg text-gray-400 mb-4">
            Your simple yet powerful survival kit. Cut out bad takes in a split-second using transcript,
            automatically remove silences, add zooms, and much more
          </p>

          {/* Buttons */}
          <div className="flex justify-center space-x-4">
            <button className="border border-2 border-white hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
            onClick={handleSignUpClick}
            >
              Try Now
            </button>
            <button className="border border-2 border-white hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
            onClick={handleDemoClick}
            >
              Demo
            </button>
          </div>
        </div>

        <Carousel />
        <Footer/>
      </div>
    </div>
  );
};

export default LandingPage;
import React, { useRef } from 'react';

import { Plus } from 'lucide-react';

import ZoomRow from './ZoomRow';

const Zoom = ({ totalDuration,
              zooms, setZooms,
              selectedZoomId, 
              setSelectedZoomId
              }) => {
  const buttonsGrid = useRef(null);

  const addZoom = function () {
    // udpate zooms using its setter
    const newId = Math.max(...zooms.map(zoom => zoom.id),0) + 1;
    const newZoom = {
      id: newId,
      start: 5,
      end: 10,
      centerX: 0.5,
      centerY: 0.5,
      scale: 1
    };
    const zoomsUpdated = [...zooms, newZoom];
    setZooms(zoomsUpdated);
    // automatically display the newly created zoom
    setSelectedZoomId(newId);
  }

  const changeDisplayedZoom = function (id) {
    //if (buttonsGrid) {
      //buttonsGrid.
    //}
    // change previous zoom button color
    setSelectedZoomId(id);
    // color the new selected zoom button

  }

  return (
    <div className='w-full mx-auto p-4 mb-12'>
      {/* button to add new zoom */}
      <button
      onClick={addZoom} 
      className='flex items-center w-12 mb-4 text-white bg-red-500 p-2 rounded'>
        <Plus/>
      </button>
      {/* buttons to select zoom to display */}
      <div 
      ref={buttonsGrid}
      className="w-32 grid grid-cols-8 mx-auto">
        {zooms.map((zoom) => (
          <button
            key={`button-zoom-${zoom.id}`}
            className={`${selectedZoomId  === zoom.id ? "bg-blue-500" : null} w-3 h-3 text-white rounded-full border border-white mb-1`}
            onClick={() => changeDisplayedZoom(zoom.id)}
          >
          </button>
        ))}
      </div>
      {/* container displaying the selected zoom information */}
      <div className='flex justify-center mt-2'>
        <ZoomRow
        totalDuration={totalDuration}
        zoomId={selectedZoomId}
        zooms={zooms}
        setZooms={setZooms}
        setSelectedZoomId={setSelectedZoomId}
        />
      </div>
    </div>
  );
};

export default Zoom;

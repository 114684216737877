import React, { useState, useEffect, useRef } from "react";
import "./style.css";

const Carousel = () => {
  const messages = [
    "Multilingual",
    "Long Media Support",
    "Flash Speed",
    "Beginner-friendly",
    "Extreme Accuracy",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const ROTATION_FREQ = 3000; // 3 secs
  const MARGIN = 100; // prevent messages to be too close to the carousel borders

  // Measure the carousel width on mount and window resize
  useEffect(() => {
    const updateWidth = () => {
      if (carouselRef.current) {
        setCarouselWidth(carouselRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const nextMessage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
  };

  useEffect(() => {
    const interval = setInterval(nextMessage, ROTATION_FREQ);
    return () => clearInterval(interval);
  }, []);

  const getMessageIndex = (offset) => {
    return (currentIndex + offset + messages.length) % messages.length;
  };

  return (
    <div className="carousel" ref={carouselRef}>
      {/* Message t-1 */}
      <div
        className="message message-left"
        style={{ transform: `translateX(-${Math.round(carouselWidth / 2) - MARGIN}px) scale(0.9)` }}
      >
        {messages[getMessageIndex(-1)]}
      </div>

      {/* Message t (center) */}
      <div className="message message-center">
        {messages[getMessageIndex(0)]}
      </div>

      {/* Message t+1 */}
      <div
        className="message message-right"
        style={{ transform: `translateX(${Math.round(carouselWidth / 2) - MARGIN}px) scale(0.9)` }}
      >
        {messages[getMessageIndex(1)]}
      </div>
    </div>
  );
};

export default Carousel;
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';

const YouTubeStyleVideoPlayer = ({
  videoRef,
  src,
  progress,
  setProgress,
  totalDuration,
  setTotalDuration,
  pixelsPerSecond,
  clips,
  currentTime,
  setCurrentTime,
  zooms, 
  setSelectedZoomId
}) => {
  const progressRef = useRef(null);
  const canvasRef = useRef(null);

  // State management
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.3);
  const [isMuted, setIsMuted] = useState(false);

  // Timeout for hiding controls
  const controlsTimeoutRef = useRef(null);

  useEffect(() => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (!video || !canvas) return;
      
      const updateCanvasSize = () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
      };
      //drawCurrentFrame();
      video.addEventListener('loadedmetadata', updateCanvasSize);
      return () => {
        video.removeEventListener('loadedmetadata', updateCanvasSize);
        };
  }, [zooms, isPlaying]);


  // Time formatting
  const formatTime = seconds => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const selectZoom = (currentTime) => {
    let left = 0;
    let right = zooms.length - 1;
    const zoomsSorted = zooms.sort((x, y) => x["start"] - y["start"]);
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const zoom = zoomsSorted[mid];
      const zoom_end = zoom.end;
    
      // current time belongs to zoom
      if (currentTime >= zoom.start && currentTime <= zoom_end) {
          return zoom;
      }
      if (currentTime < zoom.start) {
          right = mid - 1;
      } else {
          left = mid + 1;
      }
    }
    const defaultZoom = {start: -1, // whatever (not used by drawcurrentFrame)
                        end: -1, // whatever (not used by drawcurrentFrame)
                        id: -1, // whatever (not used by drawcurrentFrame)
                        centerX: 0.5,
                        centerY: 0.5,
                        scale: 1
                        };
    return defaultZoom; // no zoom to apply = default zoom
  }

  // Function to draw the current frame with zoom applied
  const drawCurrentFrame = useCallback(() => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (!video || !canvas) return;
      
      const ctx = canvas.getContext('2d');
      // 1) select zoom based on current frame time, cf video.currentTime
      const currentTime = videoRef.current.currentTime;
      const zoomSelected = selectZoom(currentTime);
      // automatically display information about the zoom being currently applied
      //if (zoomSelected.id !== -1) {
        //setSelectedZoomId(zoomSelected.id);
      //}

      // 2) apply the selected zoom
      const { start, end, id, centerX, centerY, scale } = zoomSelected;
  
      // Calculate dimensions
      const vw = video.videoWidth; // from original video (independant of HTML)
      const vh = video.videoHeight; // from original video (independant of HTML)

      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;

      const cw = canvas.width;
      const ch = canvas.height;
      // Clear canvas
      ctx.clearRect(0, 0, cw, ch);
      
      // Calculate source rectangle
      const zoomWidth = vw / scale;
      const zoomHeight = vh / scale;
      const sourceX = centerX * vw - (zoomWidth / 2);
      const sourceY = centerY * vh - (zoomHeight / 2);
      
      const dw = Math.floor(zoomWidth * ch / zoomHeight); //Math.floor(vw * ch / vh); // width of zoomed portion in canvas
      const dx = Math.max(Math.floor((cw - dw) / 2), 0); // center zoomed region on the client (i.e displayed) drawing
      // Draw the zoomed portion in canvas
      ctx.drawImage(
        video,
        sourceX, sourceY, zoomWidth, zoomHeight, // Source rectangle
        dx, 0, dw, ch                            // Destination rectangle
      );
      // ! Important to automatically apply updated zooms on video
      requestAnimationFrame(drawCurrentFrame);
  }, [zooms]);


  // Redraw the canvas whenever zoom config changes
  useEffect(() => {
      drawCurrentFrame();
  }, [zooms, drawCurrentFrame]);



  // Play/Pause toggle
  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play().catch(error => {
        console.error('Error playing video:', error);
      });
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  // Progress tracking
  const updateProgress = () => {
    const video = videoRef.current;
    const progressPercent = (video.currentTime / video.duration) * 100;
    setProgress(progressPercent);
    setCurrentTime(video.currentTime);
  };

  // Seek functionality
  const handleSeek = e => {
    const video = videoRef.current;
    const seekTime = (e.target.value / 100) * video.duration;
    video.currentTime = seekTime;
    // Critical: Update the canvas immediately after seeking
    // We need to wait a tiny bit for the video to update its current frame
    setTimeout(() => {
      drawCurrentFrame();
      }, 50);
  };

  // Volume control
  const handleVolumeChange = e => {
    const video = videoRef.current;
    const newVolume = parseFloat(e.target.value);
    video.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);

    console.log('Volume changed:', {
      newVolume,
      isMuted: newVolume === 0,
    });
  };

  // Mute toggle
  const toggleMute = () => {
    const video = videoRef.current;
    if (isMuted) {
      video.volume = volume || 1;
      setIsMuted(false);

      console.log('Unmuted:', {
        volume: volume || 1,
      });
    } else {
      video.volume = 0;
      setIsMuted(true);

      console.log('Muted');
    }
  };

  // Mouse movement to show/hide controls
  const handleMouseMove = () => {
    // Clear previous timeout
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
  };

  // Error handling
  const handleVideoError = e => {
    console.error('Video error:', e);
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', updateProgress);
      video.addEventListener('error', handleVideoError);
    }
    return () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, [videoRef.current]);

  return (
    <div
      className='relative bg-black group'
      onMouseMove={handleMouseMove}
    >
      {/* Video Element */}
      <video
        ref={videoRef}
        src={src}
        className='w-full h-80'
        style={{ display: 'none' }}
        // Add these attributes to ensure sound works
        playsInline
        preload='metadata'
        onLoadedMetadata={e => {
          setTotalDuration(e.target.duration);
          e.target.volume = volume;
          const progressPercent = (e.target.currentTime / e.target.duration) * 100;
          setProgress(progressPercent);
          setCurrentTime(0);
        }}
        
        onError={(e) => {
            console.log('Video Error:', e.target.error);
          }}
      />

      <canvas 
      ref={canvasRef}
      className='w-full h-80'
      onClick={togglePlay}
      />

      {/* Centered Play/Pause Overlay */}
      {!isPlaying && (
        <div
          className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 cursor-pointer'
          onClick={togglePlay}
        >
          <Play size={64} color='white' />
        </div>
      )}

      {/* Controls */}
      {true && (
        <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4'>
          {/* Progress Bar */}
          <input
            type='range'
            min='0'
            max='100'
            step={1/totalDuration}
            value={progress}
            onChange={handleSeek}
            ref={progressRef}
            className='appearance-none rounded bg-gray-300 absolute left-0 right-0 bottom-0 h-2 cursor-pointer z-10'
          />
          {clips.map(clip => (
            <div
            key={`timeline_clip_${clip.id}`}
            className='absolute left-0 right-0 bottom-0 h-2 bg-blue-500 z-20'
            style={{
              left: `${clip.start * pixelsPerSecond}px`,
              width: `${clip.duration * pixelsPerSecond}px`,
            }}
            />
          ))}

          {/* Time and Controls */}
          <div className='flex items-center space-x-4 text-white'>
            {/* Current Time */}
            <span className='text-sm'>{formatTime(currentTime)}</span>

            {/* Play/Pause */}
            <button
              onClick={togglePlay}
              className='hover:bg-white hover:bg-opacity-20 p-2 rounded'
            >
              {isPlaying ? <Pause size={24} /> : <Play size={24} />}
            </button>

            {/* Volume */}
            <div className='flex items-center space-x-2'>
              <button
                onClick={toggleMute}
                className='hover:bg-white hover:bg-opacity-20 p-2 rounded'
              >
                {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
              </button>
              <input
                type='range'
                min='0'
                max='1'
                step='0.1'
                value={isMuted ? 0 : volume}
                onChange={handleVolumeChange}
                className='w-24 h-1 cursor-pointer'
              />
            </div>

            {/* Total Duration */}
            <span className='text-sm ml-auto'>{formatTime(totalDuration)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const VideoTimeline = ({
  videoRef,
  videoUrl,
  progress,
  setProgress,
  totalDuration,
  setTotalDuration,
  pixelsPerSecond,
  clips,
  currentTime,
  setCurrentTime,
  zooms, 
  setSelectedZoomId
}) => {
  return (
    <div className='mx-auto max-w-xl p-4'>
      <YouTubeStyleVideoPlayer
        videoRef={videoRef}
        src={videoUrl}
        progress={progress}
        setProgress={setProgress}
        totalDuration={totalDuration}
        setTotalDuration={setTotalDuration}
        pixelsPerSecond={pixelsPerSecond}
        clips={clips}
        currentTime={currentTime}
        setCurrentTime={setCurrentTime}
        zooms={zooms}
        setSelectedZoomId={setSelectedZoomId}
      />
    </div>
  );
};

export default VideoTimeline;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function EmailConfirmation() {
  const { key } = useParams();
  const [status, setStatus] = useState('confirming');
  
  useEffect(() => {
    const confirmEmail = async () => {
      try {
        console.log("Confirming with key:", key);

        const response = await fetch(`/api/verify_email/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ key }),
        });
        
        if (response.ok) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        setStatus('error');
      }
    };
    
    confirmEmail();
  }, [key]);
  
  return (
    <div>
      {status === 'confirming' && <p className='text-white'>Confirming your email...</p>}
      {status === 'success' && <p className='text-white'>Email confirmed successfully! You can now log in.</p>}
      {status === 'error' && <p className='text-white'>There was an error confirming your email.</p>}
    </div>
  );
}

export default EmailConfirmation;
import React from 'react';

const ZoomRow = ({totalDuration,
                  zooms,
                  zoomId,
                  setZooms,
                  setSelectedZoomId
                  }) => {
  const WIDTH_PERCENT = '80%';
  const zoomSelected = zooms.find(zoom => zoom.id === zoomId);


  function formatTimeStr(t) {
    const s = (t < 10) ? `0${t}` : t;
    return s;
  }

  function ConvertSecsToHM(n_total_secs) {
    let hms = "";
    const n_hours = Math.floor(n_total_secs / 3600);
    const n_mins = Math.floor(n_total_secs / 60);
    const n_secs = Math.floor(n_total_secs % 60);
    hms = hms.concat(`${formatTimeStr(n_hours)}:`);
    hms = hms.concat(`${formatTimeStr(n_mins)}:`);
    hms = hms.concat(`${formatTimeStr(n_secs)}`);
    return hms;
  }

  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    return totalSeconds;
  }

  const handleStartChange = (e) => {
    const newStart = e.target.value; // string
    setZooms(prevZooms => 
      prevZooms.map(zoom => 
          zoom.id === zoomId
          ? { ...zoom, start: timeStringToSeconds(newStart) } // Update start only
          : zoom // Keep other zooms unchanged
      ));
  };

  const handleEndChange = (e) => {
    const newEnd = e.target.value;
    setZooms(prevZooms => 
      prevZooms.map(zoom => 
          zoom.id === zoomId
          ? { ...zoom, end: timeStringToSeconds(newEnd) } // Update end only
          : zoom // Keep other zooms unchanged
      ));
  };

  const handleCenterXChange = (e) => {
    const newCenterX = e.target.value;
    setZooms(prevZooms => 
      prevZooms.map(zoom => 
          zoom.id === zoomId
          ? { ...zoom, centerX: newCenterX } // Update centerX only
          : zoom // Keep other zooms unchanged
      ));
  };

  const handleCenterYChange = (e) => {
    const newCenterY = e.target.value;
    setZooms(prevZooms => 
      prevZooms.map(zoom => 
          zoom.id === zoomId
          ? { ...zoom, centerY: newCenterY } // Update centerY only
          : zoom // Keep other zooms unchanged
      ));
  };

  const handleScaleChange = (e) => {
      const newScale = e.target.value;
      setZooms(prevZooms => 
        prevZooms.map(zoom => 
            zoom.id === zoomId
            ? { ...zoom, scale: newScale } // Update scale only
            : zoom // Keep other zooms unchanged
        ));
  };

  const deleteZoom = (e) => {
    const zoomsUpdated = zooms.filter(zoom => zoom.id !== zoomId);
    // change selected id BEFORE deleting the current one, otherwise React re-rendering
    // will not manage to find current zoom anymore -> error
    setSelectedZoomId(Math.max(...zoomsUpdated.map(zoom => zoom.id), 0));
    // remove zoom
    setZooms(zoomsUpdated);
  }

  return (
      <div 
        className="p-2 text-white border-2 border-white rounded"
        style={{width: WIDTH_PERCENT}}
      >
      {zoomSelected && (
        <div>
          <div className='flex items-center mb-4'>
            <h2 className='text-white'>
              Zoom {zoomId}
            </h2>
            <div className='flex items-center ml-auto'>
              <button onClick={deleteZoom} className='bg-blue-500'>
                Add zoom
              </button>
            </div>
          </div>

          {/* Content container with proper spacing */}
          <div 
            style={{
              overflow: 'hidden',
              transition: 'all 0.5s ease-in-out',
              height: 'auto',
              opacity: 1,
              marginTop: '0px' // Space for the title
            }}
          >

          {/* Choose start time */}
          <div className='flex items-center gap-8 p-2'>
            <div className='flex'>
              <div className="text-white text-sm mr-2">
                Start time:
              </div>
              <input
              id={`start-${zoomId}`}
              type="time"
              min="00:00:00"
              max={ConvertSecsToHM(totalDuration)}
              step="1"
              value={ConvertSecsToHM(zoomSelected["start"])}
              onChange={handleStartChange}
              className="rounded"
              />
            </div>

            {/* Choose end time */}
            <div className='flex'>
              <div className="text-white text-sm mr-2">
                End time:
              </div>
              <input
              id={`start-${zoomId}`}
              type="time"
              min="00:00:00"
              max={ConvertSecsToHM(totalDuration)}
              step="1"
              value={ConvertSecsToHM(zoomSelected["end"])}
              onChange={handleEndChange}
              className="rounded"
              />
            </div>
          </div>

          {/* Choose x value of center point */}
          <div className='flex mt-4 items-center gap-4'>
            <input
            id={`x-${zoomId}`}
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={zoomSelected["centerX"]}
            onChange={handleCenterXChange}
            className="w-1/3 h-2 bg-gray-200 rounded appearance-none cursor-pointer"
            />
            <div className="text-white text-sm">
              Horizontal scrolling: {zoomSelected["centerX"]}
            </div>
          </div>

          {/* Choose y value of center point */}
          <div className='flex items-center gap-4'>
            <input
            id={`y-${zoomId}`}
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={zoomSelected["centerY"]}
            onChange={handleCenterYChange}
            className="w-1/3 h-2 bg-gray-200 rounded appearance-none cursor-pointer"
            />
            <div className="text-white text-sm">
                Vertical scrolling: {zoomSelected["centerY"]}
            </div>
          </div>

          {/* Choose scale value */}
          <div className='flex items-center gap-4'>
            <input
            id={`scale-${zoomId}`}
            type="range"
            min="1"
            max="5"
            step="0.1"
            value={zoomSelected["scale"]}
            onChange={handleScaleChange}
            className="w-1/3 h-2 bg-gray-200 rounded appearance-none cursor-pointer"
            />
            <div className="text-white text-sm">
                Zoom: x {zoomSelected["scale"]}
            </div>
          </div>
        </div>
      </div>
      )}
  </div>
  );
};

export default ZoomRow;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, LogOut, Info, Menu, X } from 'lucide-react'; // Using lucide-react icons

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    sessionStorage.removeItem("transcript_init");
    sessionStorage.removeItem("words");
    sessionStorage.removeItem("words_starts_time");
    sessionStorage.removeItem("words_ends_time");
    sessionStorage.removeItem("words_starts_transcript");
    sessionStorage.removeItem("media_extension");
    navigate('/');
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleNavIDClick = () => {
    navigate('/workspace');
  };

  const handleInfoClick = () => {
    navigate('/info');
  };

  return (
    <nav className="w-full bg-black-300 flex justify-between items-center text-white px-4 py-3 shadow-xl shadow-black mt-2 mb-10">
        <button 
        className="ml-8 mt-1 rounded hover:bg-black-300"
        onClick={handleNavIDClick}
        >
            <img src='/logo_navID.svg' width="80" alt="logo"/>
        </button>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-4">
          <button className="flex items-center px-3 py-2 rounded hover:bg-gray-700"
          onClick={handleInfoClick}
          >
            <Info size={18} className="mr-1" />
            More Info
          </button>
          <button 
            className="flex items-center px-3 py-2 rounded hover:bg-gray-700"
            onClick={handleSettingsClick}
            >
            <Settings size={18} className="mr-1" />
            Settings
          </button>
          <button className="flex items-center px-3 py-2 rounded hover:bg-gray-700 text-red-500"
          onClick={handleLogoutClick}
          >
            <LogOut size={18} className="mr-1" />
            Logout
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 focus:outline-none focus:bg-gray-700 rounded"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

      {isMenuOpen && (
        <div className="md:hidden mt-2 py-2">
          <button className="flex items-center w-full px-4 py-2 hover:bg-gray-700"
          onClick={handleInfoClick}
          >
            <Info size={18} className="mr-2" />
            More Info
          </button>
          <button 
          className="flex items-center w-full px-4 py-2 hover:bg-gray-700"
          onClick={handleSettingsClick}
          >
            <Settings size={18} className="mr-2" />
            Settings
          </button>
          <button className="flex items-center w-full px-4 py-2 hover:bg-gray-700 text-red-300">
            <LogOut size={18} className="mr-2" />
            Logout
          </button>
        </div>
      )}
    </nav>
  );
}

export default Navbar;

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './NavBar';

import TemplateDemoToggleable from './TemplateDemoToggleable';
import VideoCarousel from './VideoCarousel'; 

const MoreInfo = () => {
  const location = useLocation();
  const [tutoTask, setTutoTask] = useState("cut");
  const demoVideoList = [
    { id: 1,
      urlInput: 'navid_controlbar_no_preview.mp4',
      urlProcessing: 'navid_cuts_silence.mp4',
      urlOutput: 'navid_cuts_transcript.mp4',
      title: 'Example 1' 
    },
    { id: 2,
      urlInput: 'navid_cuts_silence.mp4',
      urlProcessing: 'navid_preview_download.mp4',
      urlOutput: 'navid_cuts_transcript.mp4',
      title: 'Example 2' 
    },
    { id: 3,
      urlInput: 'navid_cuts_transcript.mp4',
      urlProcessing: 'navid_fast_progress_transcript.mp4',
      urlOutput: 'navid_controlbar_no_preview.mp4',
      title: 'Example 3'
    },
  ];

  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };


  const CutTutoContent = () => {
    return (
    <div>
      <TemplateDemoToggleable 
      DemoTitle={"Upload and Transcript"}
      demoDescription={
        `<span style="color:white">Transcription is automatically triggered</span> after your file is uploaded.<br>
        Both the <span style="color:white">streaming mode</span> and the <span style="color:white">progress percentage</span> help you keep track of the current state of the transcription.<br><br>

        You can <span style="color:white">cancel a transcription</span> (and therefore the upload) at any moment by clicking on the red circle next to the progress percentage.            `
      }
      videoKey={"cut-tuto1"}
      DemoVideoUrl={"navid_fast_progress_transcript.mp4"}
      />

      <TemplateDemoToggleable 
      DemoTitle={"Cut from transcript"}
      demoDescription={
        `Once the transcript is complete, you can 
        <span style="color:white">place into brackets the sequences you would like to remove</span>
        from the media. The Scissors button automatically becomes red, letting you know
        changes have been applied on the transcript.<br><br>

        After you are done with bracketing the sequences to cut out, <span style="color:white">click on the Scissors</span> to apply
        them. <span style="color:white">Your media remains untouched</span>; however, corresponding red clips will appear
        on the progress bar below your video, enabling you to <span style="color:white">preview</span> the version <span style="color:white">without</span> those <span style="color:white">sequences</span> (see Preview and Download section) <br><br>

        To speed up the process, you can use the following <span style="color:white">shortcuts</span>: <br>
        • Highlight a sequence you want to remove, then press 'a'. Brackets will be added around the selected sequence <br>
        • Highlight a whole region of the transcript, then press 'b'. All the brackets in this region will disappear.
        `
      }
      videoKey={"cut-tuto2"}
      DemoVideoUrl={"navid_cuts_transcript.mp4"}
      />

      <TemplateDemoToggleable
      DemoTitle={"Automatic silences cutting"}
      demoDescription={
        `Removing silences is straightforward:<br>
        1) <span style="color:white">Move the slider</span> to select a minimum silence duration between 0 (left) and 10 (right) seconds. 
         <br>
        2) <span style="color:white">Check the small box</span> at the right of the slider to apply the cuts. <br><br>

        Just like for cuts from transcript, red clips will appear on the progress bar below your video, but your media
        remains untouched. The silence clips are slightly darker than the transcript clips. <br><br>
        
        You can <span style="color:white">uncheck the box to undo the cuts</span> whenever you want.
        `
      }
      videoKey={"cut-tuto3"}
      DemoVideoUrl={"navid_cuts_silence.mp4"}
      />

      <TemplateDemoToggleable
      DemoTitle={"Adjust cuts"}
      demoDescription={
        `You can <span style="color:white">fine-tune the silence and transcript clips</span> as following: <br><br>
        • <span style="color:white">Delete a cut</span> by clicking on the "close" button top right, which appears when your mouse is on a cut.<br>
        • <span style="color:white">Update an existing cut</span> by dragging its left and right borders<br>
        • <span style="color:white">Add a new cut</span> by double-clicking somewhere on the progress bar. It will be instanciated in purple, with a predefined
        duration. Make sure to update and adjust its start and end by dragging its borders.<br><br>

        You can <span style="color:white">zoom in up to 10 times bigger</span> to have more precision when adjusting your cuts.
        The home icon allows you to reset the zoom to x1. <br>
        Be careful, clicking on the <span style="color:red">bin icon removes ALL cuts</span>.
        `
      }
      videoKey={"cut-tuto4"}
      DemoVideoUrl={"navid_controlbar_no_preview.mp4"}
      />

      <TemplateDemoToggleable
      DemoTitle={"Preview and Download"}
      demoDescription={
        `<span style="color:white">Before downloading</span> your transformed media (make sure to have enough credentials, otherwise please increase them in your settings), 
        make sure to <span style="color:white">preview the result</span> using the Preview Mode.<br><br>

        The <span style="color:white">preview mode</span> corresponds to the <span style="color:white">Eye icon</span> next to the Download icon.<br>
        It is <span style="color:white">activated by default</span> (dark blue): when reviewing the video, <span style="color:white">all cuts are skipped</span> (the time cursor jumps directly to the end of every cut it encounters), as if they
        were already removed from the video (which is not yet the case).<br><br>

        Once you are 100% satisfied with those cuts, download the trimmed video.
        `
      }
      videoKey={"cut-tuto5"}
      DemoVideoUrl={"navid_preview_download.mp4"}
      />
    </div>
    )
  }

  const AudioTutoContent = () => {
    return (<div>
      <TemplateDemoToggleable
      DemoTitle={"Section 1 of Audio task"}
      demoDescription={`audio description 1`}
      videoKey={"audio-tuto1"}
      DemoVideoUrl={"navid_preview_download.mp4"}
      />
      <TemplateDemoToggleable
      DemoTitle={"Section 2 of Audio task"}
      demoDescription={`audio description 2`}
      videoKey={"audio-tuto2"}
      DemoVideoUrl={"navid_fast_progress_transcript.mp4"}
      />
    </div>
    )
  }

  const ZoomTutoContent = () => {
    return (<div>
      <TemplateDemoToggleable
      DemoTitle={"Section 1 of Zoom task"}
      demoDescription={`zoom description 1`}
      videoKey={"zoom-tuto1"}
      DemoVideoUrl={"chuic.mp4"}
      />
      <TemplateDemoToggleable
      DemoTitle={"Section 2 of Zoom task"}
      demoDescription={`zoom description 2`}
      videoKey={"zoom-tuto2"}
      DemoVideoUrl={"cnudic.mp4"}
      />
    </div>
    )
  }

  function renderTuto() {
    switch (tutoTask) {
      case 'cut':
        return CutTutoContent();
      case 'audio':
        return AudioTutoContent();
      case 'zoom':
        return ZoomTutoContent();
      default:
        return CutTutoContent();
    }
  }

  const renderNavbar = () => {
    if (location.pathname === '/info') {
      return <Navbar/>;
    } else if (location.pathname === '/demo') {
      return (<div>
          {/* Top right navigation */}
          <div className="absolute top-6 right-6 z-20 flex space-x-4">
            <button className="bg-white hover:text-gray-900 font-medium py-2 px-4 rounded-lg transition duration-300 hover:bg-gray-100"
            onClick={handleLoginClick}
            >
              Login
            </button>
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition duration-300"
            onClick={handleSignUpClick}
            >
              Sign Up
            </button>
          </div>
          </div>   
        )}
  };

  return (
    <div>
      {renderNavbar()}

      <h1 className='text-white text-3xl text-center mt-20 mb-16'>Demo</h1>

      {/* Demo videos on concrete medias */}
      <div className='w-full p-4'>
        <VideoCarousel videos={demoVideoList} />
      </div>

      <div className='mt-24 mb-12 flex justify-center'>
        {/* Toggleable explanations */}
        <div 
        style={{ width: '70%' }}
        >
          <h2 className='text-white text-center'>NavID task tutorials</h2>
          {/* choose task to detail */}
          <div className='flex ml-24 mb-8'>
              <select 
                value={tutoTask} 
                onChange={(e) => setTutoTask(e.target.value)}
                className='rounded w-24'
              >
              <option value="cut">Cut</option>
              <option value="audio">Audio</option>
              <option value="zoom">Zoom</option>
            </select>
          </div>
          {renderTuto()}
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;

import { useState, useEffect } from 'react';

const VideoCarousel = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // choose between "input", "processing" and "output"
  const [step, setStep] = useState("input");
  const [videoKey, setVideoKey] = useState(0);
  

  // Function to get the correct URL based on state
  const getStepVideoUrl = () => {
    switch(step) {
      case 'input':
        return videos[currentIndex].urlInput;
      case 'processing':
        return videos[currentIndex].urlProcessing;
      case 'output':
        return videos[currentIndex].urlOutput;
      default:
        return videos[currentIndex].urlInput; // fallback
    }
  };

  // Force video reload when step or index changes
  useEffect(() => {
    setVideoKey(prev => prev + 1);
  }, [step, currentIndex]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (!videos || videos.length === 0) {
    return <div>No videos available</div>;
  }

  return (
    <div className="flex items-center justify-center space-x-4 p-4">
      {/* Left Arrow */}
      <button 
        onClick={goToPrevious}
        className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
        aria-label="Previous video"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-8 w-8" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>

      {/* Video Player */}
      <div className="w-full max-w-2xl">
      {/* Step setter */}
        <div className='flex w-full justify-center mb-1'>
              {/* Input button */}
              <button 
              className={`rounded p-2 text-sm m-1
                ${step === "input" ? "bg-blue-500" : "bg-gray-300 hover:bg-gray-200"}
              `} 
              onClick={() => setStep('input')}>
                Input
              </button>

              {/* Processing button */}
              <button 
              className={`rounded p-2 text-sm m-1
                ${step === "processing" ? "bg-blue-500" : "bg-gray-300 hover:bg-gray-200"}
              `} 
              onClick={() => setStep('processing')}>
                Processing
              </button>

              {/* Output button */}
              <button
              className={`rounded p-2 text-sm m-1
                ${step === "output" ? "bg-blue-500" : "bg-gray-300 hover:bg-gray-200"}
              `} 
              onClick={() => setStep('output')}
              >
                Output
              </button>
        </div>
        {/* Video Player */}
        <video
          key={`video-${videoKey}`}
          controls 
          className="video-demo"
          autoPlay
          loop
        >
          <source src={getStepVideoUrl()} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p className='text-white text-center'>{videos[currentIndex].title}</p>
      </div>

      {/* Right Arrow */}
      <button 
        onClick={goToNext}
        className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
        aria-label="Next video"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-8 w-8" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  );
};

export default VideoCarousel;
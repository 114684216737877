import React from 'react';

function BackgroundVideoComponent() {
  return (
    <div className="relative h-screen w-full overflow-hidden">
      {/* Background video */}
      <video
        className="absolute top-0 left-0 min-w-full min-h-full object-cover z-0"
        autoPlay
        loop
        muted
        playsInline
        style={{ 
          transform: `scale(0.1)`,
          transition: 'transform 0.5s ease-out',
          filter: 'grayscale(100%) brightness(0.8) contrast(1.3) saturate(0.5) blur(3px) sepia(0.2)' }}
      >
        <source src="tmp.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      {/* Content that appears over the video */}
      <div className="relative z-10 h-full w-full flex items-center justify-center">
        <div className="text-white text-center p-5 bg-black bg-opacity-50 rounded">
          <h1 className="text-4xl font-bold rounded">Welcome to NavID</h1>
        </div>
      </div>
    </div>
  );
}

export default BackgroundVideoComponent;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';

const AddCredentials = ({ currentCredentials, setUserData }) => {
  const navigate = useNavigate();
  
  // Mock current credentials - in a real app, you would fetch this from your backend
  const [additionalCredentials, setAdditionalCredentials] = useState(0);
  const [totalCredentials, setTotalCredentials] = useState(currentCredentials);
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Payment form data
  const [paymentData, setPaymentData] = useState({
    cardNumber: '',
    cardHolder: '',
    expiryDate: '',
    cvv: '',
    address: '',
    city: '',
    zipCode: '',
    country: 'US'
  });
  
  // Predefined credit packages
  const creditPackages = [
    { minutes: 120, price: 3 },
    { minutes: 180, price: 4 },
    { minutes: 360, price: 7 },
    { minutes: 600, price: 10 }
  ];
  
  // Update total when additional credentials change
  useEffect(() => {
    setTotalCredentials(currentCredentials + additionalCredentials);
  }, [currentCredentials, additionalCredentials]);
  
  const handleCredentialsChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    setAdditionalCredentials(value > 0 ? value : 0);
  };
  
  const handleSelectPackage = (minutes) => {
    setAdditionalCredentials(minutes);
  };
  
  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };
  
  const formatCardNumber = (input) => {
    // Remove non-digits
    const numbers = input.replace(/\D/g, '');
    // Add space after every 4 digits
    return numbers.replace(/(\d{4})(?=\d)/g, '$1 ').trim().slice(0, 19);
  };
  
  const handleCardNumberChange = (e) => {
    const formattedValue = formatCardNumber(e.target.value);
    setPaymentData({ ...paymentData, cardNumber: formattedValue });
  };
  
  const formatExpiryDate = (input) => {
    // Remove non-digits
    const numbers = input.replace(/\D/g, '');
    // Format as MM/YY
    if (numbers.length > 2) {
      return numbers.slice(0, 2) + '/' + numbers.slice(2, 4);
    }
    return numbers;
  };
  
  const handleExpiryDateChange = (e) => {
    const formattedValue = formatExpiryDate(e.target.value);
    setPaymentData({ ...paymentData, expiryDate: formattedValue });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (additionalCredentials <= 0) {
      alert('Please select or enter a valid number of minutes to add.');
      return;
    }
    // Here you would typically process the payment
    setIsProcessing(true);
    const endpoint = "/api/update_user_field/";
    const token = localStorage.getItem('authToken');
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        credentials: 'include',
        body: JSON.stringify({"field": "credentials", "value": totalCredentials})
    })
    const data = await response.json();
    if (data.status === "ok") {
        // Update the user's credentials
        setUserData(prevData => ({
            ...prevData,
            credentials: totalCredentials
          }));
        setIsProcessing(false);
        // Navigate back to settings page
        navigate('/settings');
    }
    else {
        console.log("sorry, could not add credentials");
        window.alert("sorry, could not add credentials");
    }
  };
  
  // Calculate price based on additional credentials
  const calculatePrice = () => {
    // Find if user selected a package
    const selectedPackage = creditPackages.find(pkg => pkg.minutes === additionalCredentials);
    
    if (selectedPackage) {
      return selectedPackage.price;
    }
    
    // Custom amount pricing (approximating $0.15 per minute with volume discount)
    if (additionalCredentials <= 0) return 0;
    if (additionalCredentials < 60) return (additionalCredentials * 0.18).toFixed(2);
    if (additionalCredentials < 180) return (additionalCredentials * 0.16).toFixed(2);
    if (additionalCredentials < 360) return (additionalCredentials * 0.14).toFixed(2);
    return (additionalCredentials * 0.12).toFixed(2);
  };
  
  return (
    <div className="min-h-screen bg-black-300">
      <NavBar />
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-gray-100 shadow-md rounded-lg overflow-hidden mb-6 -mt-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6 mt-4 text-center">Add Credentials</h1>
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Minutes Summary</h2>
            
            {/* Current Credentials */}
            <div className="mb-6 pb-4 border-b border-gray-200">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-gray-700">Current Minutes</span>
                <span className="text-lg font-bold text-gray-900">{currentCredentials}</span>
              </div>
            </div>
            
            {/* Additional Credentials */}
            <div className="mb-6 pb-4 border-b border-gray-200">
              <div className="flex justify-between items-center mb-4">
                <span className="text-sm font-medium text-gray-700">Add Minutes</span>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max="600"
                    value={additionalCredentials}
                    onChange={handleCredentialsChange}
                    className="w-20 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  <span className="ml-2 text-sm text-gray-500">minutes</span>
                </div>
              </div>
              
              {/* Predefined packages */}
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 mb-2">
                {creditPackages.map((pkg, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelectPackage(pkg.minutes)}
                    className={`px-3 py-2 border rounded-md text-sm font-medium transition-colors
                      ${additionalCredentials === pkg.minutes 
                        ? 'bg-blue-100 border-blue-500 text-blue-700' 
                        : 'border-gray-300 hover:bg-gray-50'}`}
                  >
                    {pkg.minutes} min (${pkg.price})
                  </button>
                ))}
              </div>
              
              <div className="text-sm text-gray-500 mt-2">
                Select a package or enter a custom amount
              </div>
            </div>
            
            {/* Total Credentials */}
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-gray-700">Future Total Minutes</span>
                <span className="text-lg font-bold text-green-600">{totalCredentials}</span>
              </div>
              
              {/* Price */}
              {additionalCredentials > 0 && (
                <div className="flex justify-end items-center mt-2">
                  <span className="text-sm font-medium text-gray-700 mr-2">Price:</span>
                  <span className="text-xl font-bold text-blue-600">${calculatePrice()}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Payment Information */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Payment Information</h2>
            
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                {/* Card Number */}
                <div className="sm:col-span-2">
                  <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">
                    Card Number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="cardNumber"
                      name="cardNumber"
                      placeholder="1234 5678 9012 3456"
                      value={paymentData.cardNumber}
                      onChange={handleCardNumberChange}
                      maxLength="19"
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                
                {/* Card Holder */}
                <div className="sm:col-span-2">
                  <label htmlFor="cardHolder" className="block text-sm font-medium text-gray-700">
                    Card Holder Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="cardHolder"
                      name="cardHolder"
                      placeholder="John Doe"
                      value={paymentData.cardHolder}
                      onChange={handlePaymentChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                
                {/* Expiry Date */}
                <div>
                  <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">
                    Expiry Date
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="expiryDate"
                      name="expiryDate"
                      placeholder="MM/YY"
                      value={paymentData.expiryDate}
                      onChange={handleExpiryDateChange}
                      maxLength="5"
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                
                {/* CVV */}
                <div>
                  <label htmlFor="cvv" className="block text-sm font-medium text-gray-700">
                    Security Code (CVV)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="cvv"
                      name="cvv"
                      placeholder="123"
                      value={paymentData.cvv}
                      onChange={handlePaymentChange}
                      maxLength="4"
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                
                {/* Billing Address */}
                <div className="sm:col-span-2">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Billing Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder="123 Main St, Apt 4B"
                      value={paymentData.address}
                      onChange={handlePaymentChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                
                {/* City */}
                <div>
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder="New York"
                      value={paymentData.city}
                      onChange={handlePaymentChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                
                {/* Zip Code */}
                <div>
                  <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
                    ZIP / Postal Code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="zipCode"
                      name="zipCode"
                      placeholder="10001"
                      value={paymentData.zipCode}
                      onChange={handlePaymentChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                </div>
                
                {/* Country */}
                <div className="sm:col-span-2">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                    Country
                  </label>
                  <div className="mt-1">
                    <select
                      id="country"
                      name="country"
                      value={paymentData.country}
                      onChange={handlePaymentChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      required
                    >
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="UK">United Kingdom</option>
                      <option value="AU">Australia</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                      <option value="JP">Japan</option>
                      <option value="CN">China</option>
                      <option value="IN">India</option>
                      <option value="BR">Brazil</option>
                    </select>
                  </div>
                </div>
                
                {/* Submit Button */}
                <div className="sm:col-span-2 mt-4">
                  <button
                    type="submit"
                    disabled={isProcessing || additionalCredentials <= 0}
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                      ${isProcessing || additionalCredentials <= 0 
                        ? 'bg-blue-300 cursor-not-allowed' 
                        : 'bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}
                  >
                    {isProcessing ? 'Processing...' : `Pay $${calculatePrice()}`}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCredentials;